import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getInCourseTask } from '../../api/inspector'
import { finishInspeccion, getInspeccion, generateEquipamiento } from '../../api/inspeccion'
import { Ttask } from '../../app/tasksTypes'
import { UserContext } from '../../context/userContext'
import { showNotification } from '../../app/uiSlice'
import { TrootState } from '../../app/rootReducer'
import { setActiveCheck, setFinishedCheck, loadInspeccion } from './enCursoSlice'
import { Tstep } from './types'

import FormularioNavigator from './FormularioNavigator'

import Modal from '../../components/modal/modal'
import EquipamientoModal from '../../components/modal/equipamientoModal'

import emptyCheck from '../../assets/icons/ui/emptyCheck.png'
import check from '../../assets/icons/ui/check.png'

import loadingGif from '../../assets/gifs/loading.gif'


export default function EnCurso() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ taskInCourse, setTaskInCourse ] = useState<Ttask|null>(null)
  const [ allFinish, setAllFinish ] = useState<boolean>(false)
  const [ taskFinished, setTaskFinished ] = useState<boolean>(false)
  const [ loadingButton, setLoadingButton ] = useState<boolean>(false)
  const [ generarState, setGenerarState ] = useState<'idle'|'ready'|'loading'|'fulfilled'>('idle')
  const [ showModal, setShowModal] = useState<boolean>(true)
  const [ equipamientoText, setEquipamientoText ] = useState<string|undefined>(undefined)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const { checks, checkSteps, activeCheck } = useSelector((state: TrootState) => state.enCurso)

  useEffect(() => {
    if (userData.data?.inspeccionEncurso) {
      getInCourseTask(userData.data.inspeccionEncurso)
      .then((data: Ttask) => {
        setLoading(false)
        console.log({...data})

        getInspeccion(data.id_sub)
        .then((resp) => {
          console.log(resp)
          dispatch(loadInspeccion(resp))
          setTaskInCourse({...data})
        })

      })
      .catch((err) => {
        setLoading(false)
        dispatch(showNotification({
          type: 'error', message: err, toogle: Date.now()
        }))
      })
    } else {
      setLoading(false)
    }
  }, [userData])

  useEffect(() => {
    Object.entries(checkSteps).map(([idx, check]) => {
      const stepsFinished = check.steps.map(item => item.finished)
      const stillPendingStep = stepsFinished.some((item) => item === false)

      if (!stillPendingStep) dispatch(setFinishedCheck(check.checkId))
    })
  }, [checkSteps])

  useEffect(() => {
    const { marca, modelo, year, version, traccion } = checkSteps[0].steps[0].props

    if (marca && modelo && year && traccion) 
    setGenerarState('ready')
    else setGenerarState('idle')

  }, [checkSteps[0].steps[0].props])

  useEffect(() => {
    if (generarState === 'loading') {
      const timer = setTimeout(() => { setGenerarState('fulfilled') }, 15000);
      return () => clearTimeout(timer);
    }
  }, [generarState])

  useEffect(() => {
    const checksFinished = checks.map(item => item.finished)
    const stillCheckPending = checksFinished.some(item => item === false)
    
    if(!stillCheckPending) {
      setAllFinish(true)
    }
  }, [checks])

  function tasksDid(steps: Tstep[]) {
    const finished: number[] = steps.map(step => step.finished ? 1 : 0)
    return finished.reduce((prev, current) => prev + current, 0);
  }

  function finishTask() {
    const task_id = userData.data?.inspeccionEncurso
    const uuid = userData.data?.uuid

    setLoadingButton(false)
    if (task_id && uuid) {
      finishInspeccion(task_id, uuid)
      .then(resp => {
        if (resp.result && resp.result === 'success') {
          dispatch(showNotification({
            type: 'success',
            message: 'Tarea Finalizada con exito',
            toogle: Date.now() 
          }))
          setTaskFinished(true)
        }
      })
    }
  }

  return(
    <EnCursoCointainer className='EnCurso view'>

      { loading && <LoadingTask/> }

      { loading ? null : taskInCourse ? activeCheck === null ?
        <>
          <p>
            <strong> { taskInCourse && taskInCourse.marca } {' '} </strong>
            { taskInCourse && taskInCourse.modelo } {' '}
            <strong> { taskInCourse && taskInCourse.hora_inspeccion  }</strong>
          </p>

          { checks.map((check, idx) => (
            <Paso
              key={`${check.step}-${check.title}`}
              onClick={() => {
                if (!taskFinished) dispatch(setActiveCheck(check.checkId))}
              }
              finished={check.finished}
            >
              <Text> { check.title } </Text>

              <Counter>
                { tasksDid(checkSteps[check.checkId].steps) + ' de '}
                { checkSteps[check.checkId].steps.length + ' completados' }
              </Counter>
            </Paso>
          ))}

          <GenerarEquipamiento
            state={generarState}
            disabled={ generarState === 'loading'}
            onClick={() => {
              if (generarState === 'ready' || generarState === 'fulfilled') {
                const { marca, modelo, year, version, traccion } = checkSteps[0].steps[0].props
                const task_id = userData.data?.inspeccionEncurso
                generateEquipamiento({
                  id_publicacion: task_id,
                  marca: marca,
                  modelo: modelo,
                  year: year,
                  version: version,
                  transmision: traccion,
                })
                .then((resp) => {
                  console.log(resp)
                  if (resp.result === 'success' && resp.equipamiento) {
                    setGenerarState('fulfilled')
                    setEquipamientoText(resp.equipamiento)
                  }
                })
                setGenerarState('loading')
              }
            }}
          >
            { generarState === 'fulfilled' ? 'Editar Equipamiento' : 'Generar Equipamiento' }
            <LoadingGif isLoading={generarState === 'loading'} src={loadingGif}/>
          </GenerarEquipamiento>
       
          <FinishTask
            ready={ allFinish && !taskFinished ? true : false}
            onClick={() => finishTask()}
            disabled={ !allFinish || loadingButton || taskFinished ? true : false}
          >
            
            { loadingButton ? '' : taskFinished ? 'Tarea Finalizada' : 'Finalizar Tarea' }
            <LoadingGif isLoading={loadingButton} src={loadingGif}/>
          </FinishTask>
        </>

        :
        null

        :
        <NoTasks> No hay inspección en curso </NoTasks>
      }

      { activeCheck !== null &&
        <FormularioNavigator/>
      }

      { showModal && userData.data?.inspeccionEncurso && equipamientoText &&
        <Modal
          closeModal={hiddeModal}
        >
          <EquipamientoModal
            equipamiento={equipamientoText}
            publicacionId={ userData.data?.inspeccionEncurso }
            closeModal={hiddeModal}
          />
        </Modal>
      }

    </EnCursoCointainer>
  )
}

const EnCursoCointainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const LoadingTask = styled.div`
  width: 90%;
  height: 85px;
  border-radius: 5px;
  background-color: red;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #D6D6D6 0%, #F0EFED 50%, #D6D6D6 100%);
  background-size: 800px 50px;
  position: relative;
  margin-top: 10px;
`
interface Tpaso { finished: boolean }
const Paso = styled.div<Tpaso>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: left;
  font-family: Barlow;
  font-weight: 500;
  padding: 15px 5px 15px 5px;
  width: 250px;
  margin-top: 10px;
  text-align: left;
  padding: 15px 20px 15px 20px;
  border-radius: 5px;
  background-color: #FFBC42;
  color: black;
  ${(args) => args.finished && `
    background-color: #E4F0D0;
  `}
`
const Counter = styled.p`
  font-family: Montserrat;
  margin: 0px;
  font-size: 12px;
  color: gray;
`

const Text = styled.div`
`
const Check = styled.div`
  background-color: #FEFFFE;
  border-radius: 2px;
  width: 25px;
  height: 25px;
`

const NoTasks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 13;
  font-family: Barlow;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  //border: dashed 2px #000000;
  color: gray;
  width: 150px;
  margin: auto;
  margin-bottom: 100px;
`
interface TfinishTask { ready: boolean }
const FinishTask = styled.button<TfinishTask>`
  font-family: Barlow;
  font-weight: 600;
  width: 290px;
  height: 35px;
  background-color: gray;
  border-radius: 5px;
  padding: 5px 15px;
  color: white;
  border: none;
  margin-top: 20px;
  ${(args) => args.ready && `
    background-color: #2C8C99;
  `}
`
interface TloadingGif { isLoading: boolean }
const LoadingGif = styled.img<TloadingGif>`
  width: 23px;
  display: none;
  ${(args) => args.isLoading && `
    display: initial;
  `}
`

interface equipamiento { state: 'idle' | 'ready' | 'loading' | 'fulfilled' }
const GenerarEquipamiento = styled.button<equipamiento>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Barlow;
  font-weight: 600;
  width: 290px;
  height: 35px;
  background-color: gray;
  border-radius: 5px;
  padding: 5px 15px;
  color: white;
  border: none;
  margin-top: 80px;
  ${(args) => args.state === 'ready' && `
    background-color: #ff9955;
    color: black;
  `};
  ${(args) => args.state === 'loading' && `
    background-color: #5599ff;
    color: #fff;
  `};
  ${(args) => args.state === 'fulfilled' && `
    background-color: #5599ff;
    color: #fff;
  `};
`