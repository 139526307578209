import { checkPrimeSync } from 'crypto';
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getPresignedUrl, uploadImage, updateInspeccion } from '../../api/inspeccion'
import { UserContext } from '../../context/userContext'
import { TformularioCheckGrid } from './types';

import loadingGif from '../../assets/gifs/loading.gif'

export interface Tfile { blob: Blob, dataUrl: string, file: File }
interface thisProps {
  prop: TformularioCheckGrid | undefined,
  updateForm: (prop: string, observacion: string, img: Tfile) => void,
  hiddeModal: () => void,
}
export default function ModalUploadObservacion(props: thisProps) {
  const { prop, updateForm, hiddeModal } = props
  const { userData } = useContext(UserContext)
  const [ files, setFiles] = useState<Tfile[]>([])
  const [ observacion, setObservacion ] = useState<string|null>(null)
  const [ readyToUpload, setReadyToUpload ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  useEffect(() => {
    if (files.length > 0) {
      if (observacion === null) setObservacion('Observacion')
      setReadyToUpload(true)
    } else {
      setReadyToUpload(false)
    }
  }, [observacion, files])

  function inputChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    event.preventDefault()

    switch(event.target.name) {
      case 'observacion':
        setObservacion(event.target.value)
        break;
    }
  }

  async function onFileChange(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      files: FileList;
    };

    const filesRaw: File[] = []

    for (let file in target.files ) {
      if (typeof target.files[file] === 'object') {
        filesRaw.push(target.files[file])
      }
    }

    const files = filesRaw.map(file => {
      const reader = new FileReader()

      return new Promise<Tfile>(resolve => {
        reader.onload = (e) => {
          if (e.target?.result) {
            let binary = window.atob(e.target.result.toString().split(',')[1])
            let array = []
            for (var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i))
            }

            let blobData = new Blob([new Uint8Array(array)], {type: '.jpeg'})
            resolve({ blob: blobData, dataUrl: e.target.result.toString(), file })
          }
        }

        reader.readAsDataURL(file)
      })
    })

    const resultFiles = await Promise.all(files)
    setFiles([...resultFiles])
  }

  function uploadData() {
    const task_id = userData.data?.inspeccionEncurso
    setLoading(true)

    if (task_id && prop) {
      getPresignedUrl({ task_id, prop: prop.prop, imageName: `observaciones/${prop.prop}.jpeg` })
      .then(resp => {
        console.log(resp)
        uploadImage( files[0].file, resp.urlS3Image, files[0].file.type)
        .then(() => {
          if (userData.data?.inspeccionEncurso) {
            updateInspeccion({
              task_id: userData.data.inspeccionEncurso,
              [`${prop.prop}_desc`]: observacion,
              [`${prop.prop}_img`]: true,
            })
            .then((resp) => {
              setLoading(false)
              if (resp.result && resp.result === 'success' && observacion) {
                updateForm(prop.prop, observacion, files[0])
                hiddeModal()
              }
            })
          }
        })

      })
    }
  }

  return(
    <ModalUploadObservacionContainer>
      <Title> { prop?.title.toUpperCase() } </Title>

      <Input>
        <TextArea
          name="observacion"
          placeholder='Observacion'
          value={observacion ? observacion : ''}
          rows={2}
          cols={50}
          onChange={inputChange}
        />

      <UploadContainer>

        <ThumbsContainer>
          { files.map((img, idx) => (
            <Thumb src={img.dataUrl} key={`idx${img.file.name}`}/>
          ))}
        </ThumbsContainer>

        <FileUpload htmlFor="file-upload">
          IMAGEN DE RESPALDO
        </FileUpload>

        <HiddenInput 
          id='file-upload'
          type="file"
          multiple={false}
          onChange={onFileChange}
          accept="image/jpeg"
        />
      </UploadContainer>

      </Input>

      <SaveInfo
        onClick={() => uploadData()}
        disabled={!readyToUpload || loading ? true : false}
      >
        { loading ? '' : 'Guardar Info' }
        <LoadingGif isLoading={loading} src={loadingGif}/>
      </SaveInfo>

    </ModalUploadObservacionContainer>
  )
}

const ModalUploadObservacionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 15px 10px 15px;
  min-width: 290px;
  max-width: 290px;
  min-height: 200px;
  max-height: max(70vh, 290px);
  overflow: auto;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  font-family: Barlow;
  font-weight: 600;
  font-size: 15px;
  max-width: 250px;
  margin-top: 10px;
`
const Input = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`
const TextArea = styled.textarea`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  padding: 5px 5px 5px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
  width: 250px;
  margin: 0px auto 0px auto;
`
const UploadContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 10px;
`
const FileUpload = styled.label`
  font-family: Barlow;
  font-weight: 600;
  background: #EA9010;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
  width: 240px;
  margin-top: 15px;
`

const HiddenInput = styled.input`
  display: none;
`
// const ThumbsContainer = styled.div`
//   background-color: rgba(200, 200, 200, 0.4);
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: space-between;
//   margin: auto;
//   border: dashed 1px gray;
//   border-radius: 5px;
//   padding: 5px;
//   width: 260px;
//   max-height: 100px;
//   min-height: 50px;
//   overflow: auto;
// `
//   const Thumb = styled.img`
//     max-width: 60px;
//     max-height: 60px;
//     object-fit: scale-down;
//     border-radius: 5px;
//     margin: 5px;
//   `
const ThumbsContainer = styled.div`
  background-color: rgba(200, 200, 200, 0.4);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: auto;
  border: dashed 1px gray;
  border-radius: 5px;
  padding: 5px;
  width: 260px;
  max-height: 250px;
  min-height: 100px;
  overflow: auto;
`
  const Thumb = styled.img`
    max-height: 300px;
    object-fit: 'scale-down';
    border-radius: 5px;
    margin: 5px;
  `

const SaveInfo = styled.button`
  width: 270px;
  margin: 10px auto 10px auto;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 6px 6px -4px gray;
`

interface TloadingGif { isLoading: boolean }
const LoadingGif = styled.img<TloadingGif>`
  width: 23px;
  display: none;
  ${(args) => args.isLoading && `
    display: initial;
  `}
`