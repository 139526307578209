import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ls, {get,set} from "local-storage";
import { v4 as uuidv4 } from 'uuid';
import { UIContext } from '../context/uiContext';
import { UserContext } from '../context/userContext';
import { useSelector } from 'react-redux';
import { TrootState } from './rootReducer';

import './App.css';

import Sidebar from '../components/sidebar/sidebar';
import Header from '../components/header/header';
import Loading from '../components/loading/loading';
import Alert from '../components/alert/alert';

import Registro from '../views/registro/registro';
import Landing from '../views/landing/landing';
import InspeccionesLibres from '../views/inspecionesLibres/inspeccionesLibres';
import InspeccionesToDo from '../views/misTareas/inspeccionesToDo';
import EnCurso from '../views/enCurso/enCurso';


function App() {
  const { pathname } = useLocation();
  const { userData } = useContext(UserContext);
  const { uiState, setUIState } = useContext(UIContext);

  useEffect(() => {
    const viewIdx = uiState.views.findIndex((view) => view.path === pathname);
    setUIState({ ...uiState, activeViewIndex: viewIdx });
  }, [pathname])

  useEffect(() => {
    try {
      const uuid_inspector = get<string>('uuid_inspector');
      if (uuid_inspector === null) {
        set<string>('uuid_inspector', uuidv4());
        setUIState({ ...uiState, loading: false })
      }
    } catch(error) {
      alert(error)
    }

    setUIState({ ...uiState, loading: false })

  }, [])

  return (
    <div className="App">

      <Header/>
      <Sidebar/>

      <Alert/>

      <Routes>
        <Route path='/' element={<Registro/>}/>
        <Route path='/home' element={<Landing/>}/>
        <Route path='/misinspecciones' element={<InspeccionesToDo/>}/>
        <Route path='/tareas-libres' element={<InspeccionesLibres/>}/>
        <Route path='/encurso' element={<EnCurso/>}/>
      </Routes>

      <Loading/>

    </div>
  );
}

export default App;
