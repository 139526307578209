import React from 'react';
import styled from 'styled-components';

export default function Landing() {

    return(
        <LandingElement className='Landing view'>
            <p> Landing </p>
        </LandingElement>
    )
}

const LandingElement = styled.div`

`