import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { TrootState } from '../../app/rootReducer'

export default function Alert() {
  const { notification } = useSelector((state: TrootState) => state.ui)
  const [ show, setShow ] = useState<boolean>(false)

  useEffect(() => {
    if (notification) {
      setShow(true)
      setTimeout(() => { setShow(false) }, 4000)
    }
    
  }, [notification])

  return(
    <AlertComponent
      style={{zIndex: 1000 }}
      className={ show ? 'Alert showNotification' : 'Alert'}
      type={notification?.type? notification.type : 'success'}
      
    >
      <p> { notification?.message } </p>
    </AlertComponent>
  )
}

interface Talert { type: string }
const AlertComponent = styled.div<Talert>`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-family: Barlow;
  //background-color: #f14668;
  background-color: ${(args) => {
    if (args.type) {
      switch(args.type) {
        case 'error':
          return `#f14668`
          break;
        case 'success':
          return `#3F5B33`
          break;
        default:
          return `#3F5B33`
      } 
    }
  }};

  max-width: 300px;
  min-width: 300px;
  position: fixed;
  padding-left: 10px;
  padding-right: 10px;
  
  bottom: 50px;
  color: white;
  transition: all 0.5s ease-in;
  border-radius: 5px 0px 0px 5px;
`

