import { combineReducers } from '@reduxjs/toolkit'
import ui from './uiSlice'
import enCurso from '../views/enCurso/enCursoSlice'

const rootReducer = combineReducers({
    ui,
    enCurso
})

export type TrootState = ReturnType<typeof rootReducer>
export default rootReducer