import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { TrootState } from '../../app/rootReducer'
import { TcheckSteps, Tstep } from './types'
import { setFinishedCheck, setActiveCheck, nextStep, backStep } from './enCursoSlice'

import FormularioLoader from './FormularioLoader'

import back from '../../assets/icons/ui/back.png'
import leftArrow from '../../assets/icons/ui/left-arrow.png'
import rightArrow from '../../assets/icons/ui/right-arrow.png'


export default function CheckNavigator() {
  const dispatch = useDispatch();
  const { checks, activeCheck, checkSteps } = useSelector((state: TrootState) => state.enCurso)
  const [ steps, setSteps ] = useState<Tstep[]>([])
  const [ current, setCurrent ] = useState<number>(0)

  const currentStep = useSelector((state: TrootState) => {
    const { checks, activeCheck, checkSteps } = state.enCurso

    if (activeCheck !== null) {
      const currentCheck = checks.find(check => check.checkId === activeCheck)
      const currecheckSteps = checkSteps.find(check =>  check.checkId === currentCheck?.checkId)
      return currecheckSteps?.steps.find(step => step.step === currecheckSteps.currentStep)
    }
  })

  useEffect(() => {
    const currentCheckSteps = checkSteps.find(check => check.checkId === activeCheck)
    if (currentCheckSteps) {
      setSteps(currentCheckSteps.steps)
      setCurrent(currentCheckSteps.currentStep)
    }

  }, [checkSteps])

  return(
    <Check className='view' >
      <>
        <CurrentCheck onClick={() => dispatch(setActiveCheck(null))}>

          <Back> <BackIcon src={back}/> Ver Pasos </Back>
          { currentStep?.finished && <Finalizado> Finalizado </Finalizado> }          

        </CurrentCheck>

        <Title>
          { steps.length > 0 && steps[current].title }
        </Title>
      </>

      <Form>
        <FormularioLoader/>
      </Form>

      <Footer>

        <Arrow
          src={leftArrow}
          onClick={() => dispatch(backStep())}
        />
        <Dots>
          {steps.map(
            (step, idx) => 
              <Dot
                key={`dot${idx}`}
                active={ idx === current }
                finished={ step.finished }
              /> 
          )}
        </Dots>
        <Arrow
          onClick={() => dispatch(nextStep())}
          src={rightArrow}
        />

      </Footer>

    </Check>
  )
}

const Check = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
`
const Form = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  overflow: auto;
  height: 70%;
`
  const CurrentCheck = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Barlow;
    font-weight: 600;
    width: 100%;
    height: 35px;
  `
  const Back = styled.div`
    display: flex;
  `
    const BackIcon = styled.img`
      width: 20px;
      margin: 0px 10px 0px 20px;
    `

  const Title = styled.div`
    margin-top: 10px;
    font-family: Montserrat;
    font-weight: 600;
  `

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 10px;
`
  const Dots = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    min-width: 180px;
  `

  interface TdotProps { active: boolean, finished: boolean }
  const Dot = styled.div<TdotProps>`
    width: 8px;
    height: 8px;
    //border-radius: 5px;
    ${(args) => args.finished && `
      background-color: #53DD6C;
    `}
    background-color: ${(args) => args.active ? `#FFBC42` : args.finished ? '#53DD6C' : `gray`};
    
  `

  const Arrow = styled.img`
    width: 15px;
    padding: 20px;
  `

const Finalizado = styled.p`
  margin: 0px 20px 0px 0px;
  color: #53DD6C;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 5px;
`