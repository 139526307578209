import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk, AppDispatch } from './store'
import { TuiState, Tview, Tnotification  } from './uiTypes'

const views = [
  { path: '/', title: 'Registro', titleShort: 'Registro' },
  { path: '/home', title: 'Landing', titleShort: 'Landing' },
  { path: '/tareas-libres', title: 'Inspecciones Libres', titleShort: 'Tareas Libres',  }
]

const initialUIState: TuiState = {
  loading: true,
  showSideBar: false,
  showModal: false,
  notification: undefined,
  activeView: views[0],
  views,
}
  
const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUIState,
  reducers: {
    setActiveView(state, action: PayloadAction<Tview>) {
      state.activeView = action.payload
    },
    showNotification(state, action: PayloadAction<Tnotification>) {
      state.notification = action.payload
    },
    displayModal(state) {
      state.showModal = true
    },
    hiddeModal(state) {
      state.showModal = false
    },
    displayLoading(state) {
      state.loading = true
    },
    hiddeLoading(state) {
      state.loading = false
    },
    displaySidebar(state) {
      state.showSideBar = true
    },
    hiddeSidebar(state) {
      state.showSideBar = false
    }
  }
})

export const {
  setActiveView,
  showNotification,
  displayModal,
  hiddeModal,
  displayLoading,
  hiddeLoading,
  displaySidebar,
  hiddeSidebar,
} = uiSlice.actions

export default uiSlice.reducer