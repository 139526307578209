import React, { useContext } from 'react';
import styled from 'styled-components';

import menuIcon from '../../assets/icons/ui/menu.png';
import { UIContext } from '../../context/uiContext';

export default function Header() {
  const { uiState, setUIState } = useContext(UIContext);

  function getCurrentView() {
    return uiState.views[uiState.activeViewIndex]
  }

  return(
    <HeaderElement>

      <LeftSide
        onClick={() => { setUIState({ ...uiState, showSideBar: true }) }}
      >
        <img
          src={menuIcon}
          width={24}
          height={24}
        />
      </LeftSide>

      <Center>
        <Title>
          <p> { getCurrentView().title } </p>
        </Title>
      </Center>

      <RightSide/>

    </HeaderElement>
  );
};

const HeaderElement = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  height: 60px;
  border-bottom: solid 1px rgb(216, 216, 216);
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

  const Title = styled.div`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
  `

const RightSide = styled.div`
  display: flex;
  width: 50px;
`;