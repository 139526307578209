import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { TresponseError, Ttask } from '../../app/tasksTypes'
import { meses, dias } from '../../app/consts'
import { asignarmeTask } from '../../api/inspector'
import { setTaskEnCurso } from '../../api/inspector'
import { showNotification } from '../../app/uiSlice'
import { useDispatch } from 'react-redux'

import loadingGif from '../../assets/gifs/loading.gif';
import { UserContext } from '../../context/userContext'
import { useLocation } from 'react-router-dom'


interface thisProps { task: Ttask}
export default function ModalInspeccionToDo(props: thisProps) {
  const { task } = props
  const dispatch = useDispatch()
  const [ loading, setLoading ] = useState(false)
  const [ enCurso, setEnCurso ] = useState(false)

  const date = new Date(task.fecha_inspeccion)

  function comenzarTarea(taskId: string) {
    setLoading(true)

    setTaskEnCurso(taskId)
    .then(data => {
      setLoading(false)
      if (data.result === 'success') {
        setEnCurso(true)
        window.location.reload();  
      }
    })
    .catch((err: string) => {
      setLoading(false)
      if (err.match('Ya se esta realizando')) {
        dispatch(showNotification({ type: 'error', message: err, toogle: Date.now() }))
      }
      dispatch(showNotification({ type: 'error', message: err, toogle: Date.now() }))
    });
  }
  
  return(
    <ModalInspeccionesLibres>

      <Title> Inspeccion Por Hacer </Title>
      <Text> <Bold> Direccion: </Bold> { task.address } </Text>
      <Text> <Bold> Vehiculo: </Bold> { task.marca } </Text>
      <Text> <Bold> Modelo: </Bold> { task.modelo } </Text>
      <Text> <Bold> Año: </Bold> { task.year } </Text>

      <Text>
        <Bold> Fecha: </Bold>
        { dias[date.getDay()] },
        { ' ' + date.getDate() }
        { ' ' + meses[date.getMonth()] }
      </Text>
      <Text> <Bold> Hora: </Bold> { task.hora_inspeccion } </Text>

      <Button
        onClick={() => comenzarTarea(task.id_sub)}
        disabled={loading || enCurso}
      >
        { loading && <Loading src={loadingGif}/> }
        { loading ? 'COMENZANDO' : enCurso ? 'EN CURSO' : 'COMENZAR INSPECCION' }
      </Button>

    </ModalInspeccionesLibres>
  )
}

const ModalInspeccionesLibres = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 30px 15px 30px 15px;
  min-width: 290px;
  max-width: 290px;
  min-height: 290px;
`
const Title = styled.div`
  width: 170px;
  margin: 0px auto 20px auto;
  font-weight: 800;
  font-size: 18px;
`
const Text = styled.p`
  font-family: Montserrat;
  line-height: 20px;
  margin: 5px;
  text-align: left;
  font-size: 15px;
`
const Bold = styled.strong`
  font-style: 800;
  font-size: 14px;
  margin-right: 5px;
`
const Button = styled.button`
  display: flex;
  flex: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #EDB458;
  font-family: Barlow;
  font-weight: 800;
  font-size: 16px;
  width: 160px;
  margin: 20px auto 0px auto;
  padding: 5px 10px 5px 10px;
`
const Loading = styled.img`
  width: 20px;
`