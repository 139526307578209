import React, { useContext, useEffect, useState } from "react";
import styled from 'styled-components'
import { UIContext } from "../../context/uiContext";
import { UserContext } from "../../context/userContext";

import ViewItem from "./sidebarViewItem";

import logoCarp10 from '../../assets/icons/logoCarp10.jpeg'


export default function Sidebar() {
  const { userData } = useContext(UserContext)
  const { uiState, setUIState } = useContext(UIContext);
  const [ inCourse, setInCourse ] = useState<boolean>(false)

  useEffect(() => {
    if (userData.data?.inspeccionEncurso) setInCourse(true)
    else setInCourse(false)

  }, [userData])

  function HiddeSidebar() {
    setUIState({ ...uiState, showSideBar: false });
  };

  return(
    <>
      <SidebarBackground
        showSideBar={uiState.showSideBar}
        className="SidebarBackground"
        onClick={ () => HiddeSidebar() }
        style={{ zIndex: 998 }}
      />

      <SidebarMenu
        showSideBar={uiState.showSideBar}
        className="SideBar"
        style={{ zIndex: 999 }}
      >
        <TopSide>
          <TitleSidebar className="TitleSidebar">
            Menu
          </TitleSidebar>

          <ListViews>
            {
              uiState.views.map((view, idx) => (
                <ViewItem
                  key={`sideView${idx}`}
                  active={uiState.activeViewIndex === idx ? true : false}
                  inCourse={inCourse}
                  {...view} 
                />
              ))
            }
          </ListViews>
        </TopSide>

        <BottomSide>
          <Logo src={logoCarp10}/>
          <p> { userData.data?.uuid } </p>
          <p> { userData.data?.email } </p>
        </BottomSide>

      </SidebarMenu>
    </>
  )
}

interface TSidebarProps { showSideBar: boolean }
const SidebarBackground = styled.div<TSidebarProps>`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .2s ease-out;
  ${ props => props.showSideBar && `
      opacity: 1;
      left: 0px;
      right: 0px;
  `};
`
const SidebarMenu = styled.div<TSidebarProps>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -300px;
  width: 300px;
  background-color: white;
  z-index: 999;
  transition: transform .5s ease-out;
  ${ props => props.showSideBar && `
      transform: translate(300px, 0);        
  `};
`
const TopSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const BottomSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: 10px;
`;

  const Logo = styled.img`
    width: 100px;
    margin-bottom: 50px;
  `

const TitleSidebar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
`
const ListViews = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
