import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { updateInspeccion } from '../../../api/inspeccion';
import { UserContext } from '../../../context/userContext';
import { setFinishedStep, updatePropInStepCheck0 } from '../enCursoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TrootState } from '../../../app/rootReducer';
import Toggle from 'react-toggle'
import "react-toggle/style.css"

const cities = [
  'Santa Cruz de La Sierra', 'Warnes', 'Cotoca'
];

const motorCCValues = [1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 3500, 3600, 4000, 5600, 6000]

type dataForm = {
  odometro: number | undefined,
  odometro_unidad: 'kilometros' | 'millas' | undefined,
  ciudad: string | undefined,
  motor_cc: number | undefined,
  propietarios_anteriores: string | undefined,
  permutable: boolean | undefined,
  de_agencia: boolean | undefined,
}
const initialForm = {
  odometro: undefined,
  odometro_unidad: undefined,
  ciudad: undefined,
  motor_cc: undefined,
  propietarios_anteriores: undefined,
  permutable: false,
  de_agencia: false,
}

export default function DatosGenerales_02() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [ dataForm, setDataForm ] = useState<dataForm>(initialForm)
  const [ readyToUpload, setReadyToUpload  ] = useState<boolean>(false)

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[0].steps[1]
  })

  useEffect(() => {
    setDataForm((state) => {
      return {
        ...state,
        ...currentStep.props
      }
    })

    const therIsUndefined = Object.entries(currentStep.props).some(([prop, valor]) =>  valor === undefined)
    if (!therIsUndefined) dispatch(setFinishedStep())
  }, [])

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    switch(event.target.name) {
      case 'check_odometro':
        setDataForm({ ...dataForm, odometro: Number(event.target.value) })
        dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { odometro: event.target.value } }))
        break;
      case 'check_odometro_unidad':
        if (event.target.value === 'kilometros' || event.target.value === 'millas') {
          setDataForm({ ...dataForm, odometro_unidad: event.target.value })
          dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { odometro_unidad: event.target.value } }))
        } else console.log('No se ha proveido los valores aceptables para la unidad del odometro')
        break;
      case 'check_ciudad':
        setDataForm({ ...dataForm, ciudad: event.target.value })
        dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { ciudad: event.target.value } }))
        break;
      case 'check_cilindrada':
        setDataForm({ ...dataForm, motor_cc: Number(event.target.value) })
        dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { motor_cc: Number(event.target.value) } }))
        break;
      case 'check_propietarios_anteriores':
        setDataForm({ ...dataForm, propietarios_anteriores: event.target.value })
        dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { propietarios_anteriores: event.target.value } }))
        break;
      case 'check_deagencia':
        const targetAgencia = event.target as typeof event.target & { checked: boolean}
        setDataForm({ ...dataForm, de_agencia: targetAgencia.checked })
        dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { de_agencia: targetAgencia.checked } }))
        break;
      case 'check_permutar':
        const targetPermutable = event.target as typeof event.target & { checked: boolean}
        setDataForm({ ...dataForm, permutable: targetPermutable.checked })
        dispatch(updatePropInStepCheck0({ stepIdx: 1, payload: { permutable: targetPermutable.checked } }))
        break;
    }    
  }

  useEffect(() => {
    isReadyToUpload()
  }, [dataForm])

  function isReadyToUpload() {
    const therIsUndefined = Object.entries(dataForm).some(([prop, valor]) => {
      console.log(`${prop} : ${valor}`, valor === undefined)
    return valor === undefined
  })
    console.log(therIsUndefined)
    if (!therIsUndefined) setReadyToUpload(true)
  }

  function uploadData() {
    if (userData.data?.inspeccionEncurso) {
      updateInspeccion({ task_id: userData.data.inspeccionEncurso, ...dataForm })
      .then((resp) => {
        console.log(resp)
        if (resp.result && resp.result === 'success') {
          dispatch(setFinishedStep())
        }
      })
    }
  }

  return(
    <Formulario>

      { currentStep?.finished && <Finalizado> Finalizado </Finalizado> }

      <Row>
        <Input>
          <Label htmlFor='check_odometro'> Kilometraje Actual: </Label>
          <Field
            type='number'
            id='check_odometro'
            name='check_odometro'
            defaultValue={dataForm.odometro}
            onChange={inputChange}
          />
        </Input>

        {/* <Input> */}
          {/* <Label htmlFor='check_propietarios_anteriores'> Dueños anteriores: </Label> */}
          <SelectOdometro
            id="check_odometro_unidad"
            name='check_odometro_unidad'
            value={dataForm.odometro_unidad ? dataForm.odometro_unidad : 'DEFAULT'}
            onChange={inputChange}
          >
            <option value="DEFAULT" disabled>Unidad</option>
            <option value='kilometros'> Km </option>
            <option value='millas'> Mi </option>
          </SelectOdometro>
        {/* </Input> */}
      </Row>

      <Input>
        <Label htmlFor='check_cilindrada'> Cilindrada: </Label>
        <Select
          id="check_cilindrada"
          name='check_cilindrada'
          value={dataForm.motor_cc ? dataForm.motor_cc : 'DEFAULT'}
          onChange={inputChange}
        >
          <option value="DEFAULT" disabled>Cilindrada</option>
          {
            motorCCValues.map((city, index) => (
              <option value={city} key={`city${index}`}>
                { city }
              </option>
            ))
          }
        </Select>
      </Input>

      <Input>
        <Label htmlFor='check_ciudad'> Ciudad: </Label>
        <Select
          id="check_ciudad"
          name='check_ciudad'
          value={dataForm.ciudad ? dataForm.ciudad : 'DEFAULT'}
          onChange={inputChange}
        >
          <option value="DEFAULT" disabled>Ciudad</option>
          {
            cities.map((city, index) => (
              <option value={city} key={`city${index}`}>
                { city }
              </option>
            ))
          }
        </Select>
      </Input>

      <Input>
        <Label htmlFor='check_propietarios_anteriores'> Dueños anteriores: </Label>
        <Select
          id="check_propietarios_anteriores"
          name='check_propietarios_anteriores'
          value={dataForm.propietarios_anteriores ? dataForm.propietarios_anteriores : 'DEFAULT'}
          onChange={inputChange}
        >
          <option value='DEFAULT' disabled>Dueños anteriores</option>
          <option value='primero'> 1er y Único dueño </option>
          <option value='segundo'> 2do dueño </option>
          <option value='tercero'> 3er dueño </option>
        </Select>
      </Input>

      <Row>
        <Label htmlFor='check_deagencia'>¿Sacado de agencia?</Label>
        <Toggle
          id='check_deagencia'
          name='check_deagencia'
          checked={dataForm.de_agencia ? dataForm.de_agencia : false}
          onChange={inputChange}
        />
      </Row>

      <Row>
        <Label htmlFor='check_permutar'>¿Permutaría por otro vehículo?</Label>
        <Toggle
          id='check_permutar'
          name='check_permutar'
          checked={dataForm.permutable ? dataForm.permutable : false}
          onChange={inputChange}
        />
      </Row>

      <SaveInfo
        onClick={() => uploadData()}
        disabled={!readyToUpload}
      >
        Guardar Info
      </SaveInfo>

    </Formulario>
  )
}

const Formulario = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 300px;
  margin: 5px auto auto auto;
  gap: 10px;
`
const Input = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`
const Label = styled.label`
  display: flex;
  font-family: Barlow;
`
const Field = styled.input`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin-top: 15px;
  padding: 0px 5px 0px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
`
const Select = styled.select`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin-top: 15px;
  padding: 7px 5px 6px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
`
const SelectOdometro = styled(Select)`
  align-self: flex-end;  
`

const SaveInfo = styled.button`
  margin-top: 30px;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 8px 8px -4px gray;
`

const Finalizado = styled.p`
  margin: 0px;
  color: #53DD6C;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 5px;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`