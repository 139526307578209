import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Tstep } from './types'

import NoFormulario from './formularios/noFormulario'
import DatosGenerales_01 from './formularios/datosGenerales_01'
import DatosGenerales_02 from './formularios/datosGenerales_02'

import Escaneo_Checks from './formularios/formularioChecks'
// import Escaneo_02 from './formularios/escaneo02_interior'
import Escaneo_03 from './formularios/escaneo_03'
// import Escaneo_04 from './formularios/escaneo04_enmarcha'
import Escaneo_05 from './formularios/escaneo_05'
// import Escaneo_06 from './formularios/escaneo06_capo'
// import Escaneo_07 from './formularios/escaneo07_exterior'
// import Escaneo08_underbody from './formularios/escaneo08_underbody'
import Escaneo_09 from './formularios/escaneo_09'

import Avaluo01_precioCliente from './formularios/avaluo01_precioCliente'
import Avaluo02PrecioEvaluador from './formularios/avaluo02_precioEvaluador'

import Fotos01_exterior from './formularios/fotos01_exterior'
import Fotos02_exterior from './formularios/fotos02_exterior'
import Fotos03_exterior from './formularios/fotos03_exterior'
import Fotos02_interior from './formularios/fotos02_interior'

import { useSelector } from 'react-redux'
import { TrootState } from '../../app/rootReducer'

export default function CheckLoader() {
  const { checks, activeCheck, checkSteps } = useSelector((state: TrootState) => state.enCurso)
  const [ steps, setSteps ] = useState<Tstep[]>([])
  const [ current, setCurrent ] = useState<number>(0)

  //const enCurso = useSelector((state: TrootState) => state.enCurso)
  //useEffect(() => {}, [enCurso])

  useEffect(() => {
    const currentCheckSteps = checkSteps.find(check => check.checkId === activeCheck)
    if (currentCheckSteps) {
      setSteps(currentCheckSteps.steps)
      setCurrent(currentCheckSteps.currentStep)
    }

  }, [checkSteps])

  if (steps.length > 0) {

    switch(steps[current].component) {
      case 'datosGenerales_01':
        return <DatosGenerales_01/>

      case 'datosGenerales_02':
        return <DatosGenerales_02/>
      
      case 'escaneo_01': // Escaneo General
        return(
          <Escaneo_Checks
            key={'Escaneo General'}
            initialFormValues={ checkSteps[1].steps[0].props }
            finished={ checkSteps[1].steps[0].finished }
          />
        )

      case 'escaneo_02': // Interior Vehiculo
        return(
          <Escaneo_Checks
            key={'Interior Vehiculo'}
            initialFormValues={ checkSteps[1].steps[1].props }
            finished={ checkSteps[1].steps[1].finished }
          />
        )

      case 'escaneo_03':
        return <Escaneo_03/>

      case 'escaneo_04': // Vehiculo En Marcha
        return(
          <Escaneo_Checks
            key={'Vehiculo En Marcha'}
            initialFormValues={ checkSteps[1].steps[3].props }
            finished={ checkSteps[1].steps[3].finished }
          />
        )

      case 'escaneo_05':
        return <Escaneo_05/>

      case 'escaneo_06': // Bajo El Capo
        return(
          <Escaneo_Checks
            key={'Bajo El Capo'}
            initialFormValues={ checkSteps[1].steps[5].props }
            finished={ checkSteps[1].steps[5].finished }
          />
        )

      case 'escaneo_07': // Exterior del Vehiculo
        return(
          <Escaneo_Checks
            key={'Exterior del Vehiculo'}
            initialFormValues={ checkSteps[1].steps[6].props }
            finished={ checkSteps[1].steps[6].finished }
          />
        )

      case 'escaneo_08':
        return(
          <Escaneo_Checks
            key={'Maletero'}
            initialFormValues={ checkSteps[1].steps[7].props }
            finished={ checkSteps[1].steps[7].finished }
          />
        )
{/*
      case 'escaneo_09':
        return(
          <Escaneo_Checks
            key={'UnderBody'}
            initialFormValues={ checkSteps[1].steps[8].props }
            finished={ checkSteps[1].steps[8].finished }
          />
        )
*/}
      case 'escaneo_09':
        return <Escaneo_09/>

      case 'avaluo_01':
        return <Avaluo01_precioCliente/>

      case 'avaluo_02':
        return <Avaluo02PrecioEvaluador/>

      case 'fotos_ext_01':
        return <Fotos01_exterior/>

      case 'fotos_ext_02':
        return <Fotos02_exterior/>

      case 'fotos_ext_03':
        return <Fotos03_exterior/>

      case 'fotos_int_01':
        return <Fotos02_interior/>

      case 'noFormulario':
        return <NoFormulario/>
    }

  }
  

  return(
    <p> { steps.length > 0 && steps[current].title } </p>
  )

}

const CheckLoaderContainer = styled.div`
  display: flex;
`