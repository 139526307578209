import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export type Tview = {
  title: string,
  path: string,
  titleShort: string,
}

type TuiState = {
  loading: boolean,
  showSideBar: boolean,
  views: Tview[],
  activeViewIndex: number,
}

type TUIContextType = {
  uiState: TuiState,
  setUIState: (uiState: TuiState) => void;
};

const views = [
  { path: '/', title: 'Registro', titleShort: 'Registro' },
  { path: '/home', title: 'Landing', titleShort: 'Landing' },
  { path: '/misinspecciones', title: 'Mis Inspecciones', titleShort: 'Mis Inspecciones' },
  { path: '/tareas-libres', title: 'Inspecciones Libres', titleShort: 'Tareas Libres' },
  { path: '/encurso', title: 'Inspeccion En Curso', titleShort: 'En Curso',  }
]

const initialUIState: TuiState = {
  loading: true,
  showSideBar: false,
  activeViewIndex: 0,
  views,
}

export const UIContext = createContext<TUIContextType>({
  uiState: initialUIState,
  setUIState: (uiState: TuiState) => {},
});

type providerProps = {
  children: React.ReactNode;
};

const UIProvider: React.FC<providerProps> = ({ children }) => {
  const [uiState, setUIState] = useState<TuiState>(initialUIState);
  const {pathname} = useLocation()

  useEffect(() => {
    const idx = uiState.views.findIndex((view) => view.path === pathname)
    setUIState({
      ...uiState,
      loading: false,
      activeViewIndex: idx,
    })
    

  }, [])

  return (
    <UIContext.Provider value={{ uiState, setUIState }}>
      { children }
    </UIContext.Provider>
  );
};

export default UIProvider;
