import { rejects } from "assert";
import ls, {get,set} from "local-storage";
import { resolve } from "path";
import { TresponseError, TsetEnCursoTask, Ttask, TtasksResponse } from '../app/tasksTypes';

const BACKEND_URL = 'https://api.carp10.com';

export function loadInspeccionesLibres() {
  const payload = {
    type: "getTasks",
    uuid: get<string>('uuid_inspector'),
  };

  return new Promise<TtasksResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Inspeccion Libres - Tareas: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve({...data})
    })
    .catch((error) => console.log('userInfo error', error));
  })
}

export function asignarmeTask(task_id: string) {
  const payload = {
      type: "asignarmeTask",
      uuid: get<string>('uuid_inspector'),
      task_id
  };

  return new Promise<string>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
        console.log("Inspeccion Libres - Asignacion: ", data);

        if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
        resolve('success');
    })
    .catch((error) => {
        console.log('userInfo error', error);
        reject(error)
    });
  })
}

export function loadMyTasks() {
  const payload = {
      type: "getMyTasks",
      uuid: get<string>('uuid_inspector'),
  };

  return new Promise<TtasksResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Mis Inspecciones - response: ", data);

      if (data.statusCode && data.statusCode !== 200) reject(data.message)
      resolve(data)
    })
    .catch((error) => reject(error));
  })
}

export function setTaskEnCurso(task_id: string) {
  const payload = {
    type: "setOnGoingTask",
    uuid: get<string>('uuid_inspector'),
    task_id
  };

  return new Promise<TsetEnCursoTask>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Set EnCurso - response: ", data);

      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => {
      console.log(error)
    });
  })
}

export function getInCourseTask(task_id: string) {
  const payload = {
    type: "getOnGoingTask",
    uuid: get<string>('uuid_inspector'),
    task_id
  };

  return new Promise<Ttask>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Get EnCurso - response: ", data);

      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => {
      console.log(error)
    });
  })
}