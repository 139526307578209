import ls, {get,set} from "local-storage";
import * as AWS from 'aws-sdk';
import axios from 'axios'
import {
  TresponseError,
  TsetEnCursoTask,
  Ttask,
  TequipamientoResponse,
  TtasksResponse,
  preSignedUrlResp,
  TsimpleResponse
} from '../app/tasksTypes';

import { TcheckOnCourseResponse } from "../views/enCurso/typesApi";
import { AxiosResponse, AxiosError } from "axios"

AWS.config.update({region: 'sa-east-1'});

const BACKEND_URL = 'https://api.carp10.com';

type Tpayload = {
  task_id: string,
  marca?: boolean | string | number | undefined,
  modelo?: boolean | string | number | undefined,
  version?: boolean | string | number | undefined,
  year?: boolean | string | number | undefined,

  transmision?: string,
  numero_llaves?: number,

  sonido_motor?: boolean,

  ownerMinPrice?: number,
  ownerMaxPrice?: number,

}

export function getInspeccion(task_id: string) {
  const payload = {
    type: "getInspeccion",
    task_id
  };

  return new Promise<TcheckOnCourseResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("getInspeccion: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('getInspeccion error', error));
  })
}

export function updateInspeccion(toUpdate: Tpayload) {
  const payload = {
    type: "updateInspeccion",
    ...toUpdate,
  };

  return new Promise<TtasksResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("UpdateInspeccion: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('userInfo error', error));
  })
}

export function finishInspeccion(task_id: string, uuid: string) {
  const payload = {
    type: "finishTask",
    task_id,
    uuid
  };

  return new Promise<TtasksResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Finish Inspeccion: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('userInfo error', error));
  })
}

interface TgetPresignedUrlProps { task_id: string, prop: string, imageName: string }
export function getPresignedUrl(props: TgetPresignedUrlProps) {
  const  { task_id, prop, imageName } = props;

  const payload = {
    type: "uploadImage",
    task_id, prop, imageName,
  };

  return new Promise<preSignedUrlResp>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Presigned Url: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('userInfo error', error));
  })
}

export function uploadImage(file: File|Blob, slug: string, type: string) {
  return new Promise<TsimpleResponse>((resolve, reject) => {
    fetch(slug, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: file
    })
    .then(data => {
      console.log("Presigned Url: ", 'success');
      //if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve({ result: 'success' })
    })
    .catch((error) => console.log('userInfo error', error));
  })
}

export function uploadImage2(image: File|string, slug: string, type: string) {
  const axiosOptions = {
    method: 'PUT',
    url: slug,
    headers: {
      'Content-Type': type //'application/octet-stream'
    },
    body: image
  };

  return new Promise<AxiosResponse<preSignedUrlResp>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('Error to upload image')
    })
  })
}

export function generateEquipamiento(data: any) {

  const payload = {
    type: "generarEquipamiento",
    ...data,
  };

  return new Promise<TequipamientoResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("generarEquipamiento: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('getInspeccion error', error));
  })
}

export function updateEquipamiento(data: any) {

  const payload = {
    type: "updateEquipamiento",
    ...data,
  };

  return new Promise<TsimpleResponse>((resolve, reject) => {
    fetch(`${BACKEND_URL}/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log("generarEquipamiento: ", data);
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('getInspeccion error', error));
  })
}