import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, AppDispatch } from '../../app/store'

import { Tcheck, TcheckOnCourse, TcheckSteps } from './types'
import { TcheckOnCourseResponse } from './typesApi'



const checkSteps: TcheckSteps = [
  {
    checkId: 0,
    currentStep: 0,
    steps :[
      { step: 0, finished: false, title: 'Datos Generales del vehiculo', component: 'datosGenerales_01',
        props: {
          marca: undefined,
          modelo: undefined,
          version: undefined,
          year: undefined,
          traccion: undefined,
        }
      },
      { step: 1, finished: false, title: 'Datos del vehiculo', component: 'datosGenerales_02',
        props: {
          odometro: undefined,
          odometro_unidad: undefined,
          ciudad: undefined,
          motor_cc: undefined,
          propietarios_anteriores: undefined,
          permutable: false,
          de_agencia: false,
        }
      },
    ] 
  },
  {
    checkId: 1,
    currentStep: 0,
    steps: [
      { step: 0, finished: false, title: 'Escaneo General', component: 'escaneo_01', props: [
        { value: undefined, prop: 'computadora_motor', title: 'Computadora del motor', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'computadora_caja', title: 'Computadora de la caja', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'airbags', title: 'Airbags', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'aire_acondicionado', title: 'Aire acondicionado', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'panel_instrumento', title: 'Panel de instrumento', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'inmovilizador_llave', title: 'Inmovilizador de la llave / Llave con chip (CODE / IMMO / SMK)', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'eps', title: 'EPS (Dirección asistida)', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'bcm', title: 'BCM (Body Control Module)', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'awd_4wd', title: '4WD/AWD', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'tpms', title: 'TPMS (sistema de monitoreo de presión de neumáticos)', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'odometro_real', title: 'Odómetro real', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'inyeccion_pareja', title: 'Inyeccion pareja de gasolina', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 1, finished: false, title: 'Equipamiento interior', component: 'escaneo_02', props: [
        { value: undefined, prop: 'control_vidrios', title: 'Control Master de Vidrios eléctricos (delantero y traseros)', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'controles_volante', title: 'Controles en el volante', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'ac_interruptores', title: 'A/C interruptores', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'ac_sistema', title: 'A/C sistema', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'ac_salida_delantera', title: 'Salida de A/C delanteras', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'ac_salida_trasera', title: 'Salida de A/C traseras', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'radio', title: 'Radio', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'retrovisores_plegables', title: 'Espejos retrovisores plegables', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'camara_retro', title: 'Cámara de retro', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 2, finished: false, title: '¿Tipo de transmisión del vehículo?', component: 'escaneo_03', props: [
        {value: undefined, prop: 'transmision', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 3, finished: false, title: 'Seguridad en carretera', component: 'escaneo_04', props: [
        { value: undefined, prop: 'direccion', title: 'Dirección', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'terminales_direccion', title: 'Terminales de dirección', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'cinturones_seguridad', title: 'Cinturones de seguridad', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'luz_freno', title: 'Luz de freno', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'pastillas_freno', title: 'Pastillas de freno', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'llantas_neumaticos', title: 'Llantas/Neumáticos', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'aros_originales', title: 'Aros originales', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'limpia_parabrisas_delanteros', title: 'Limpia parabrisas delanteros', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'limpia_parabrisas_traseros', title: 'Limpia parabrisas traseros', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 4, finished: false, title: 'Sonido del motor', component: 'escaneo_05', props: [
        { value: undefined, prop: 'sonido_motor', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 5, finished: false, title: 'Bajo el capó', component: 'escaneo_06', props: [
        { value: undefined, prop: 'motor_sonido', title: 'Sonido del motor', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'plastico_delantero', title: 'Plástico delantero', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'correa', title: 'Correa', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'radiador_mangueras', title: 'Mangueras del radiador', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'bateria_agua', title: 'Agua de Batería', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'bateria', title: 'Bateria', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'alternador', title: 'Alternador', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'motor_filtro_aire', title: 'Filtro de aire del motor', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'motor_aceite', title: 'Aceite del motor', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'motor_aceite_espesor', title: 'Espesor del aceite', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'freno_liquido', title: 'Liquido de freno', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'liquido_refrigerante', title: 'Liquido refrigerante', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'compresor_aire_acondicionado', title: 'Compresor del aire acondicionado', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'gas_refrigerante', title: 'Gas refirgerante', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 6, finished: false, title: 'Estética del vehículo', component: 'escaneo_07', props: [
        { value: undefined, prop: 'zonas_repintadas', title: 'Revisar zonas repintadas', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'zonas_abolladuras', title: 'Revisar zonas con abolladuras', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'zonas_raspaduras', title: 'Revisar zonas con raspaduras', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 7, finished: false, title: 'Maletero', component: 'escaneo_08', props: [
        { value: undefined, prop: 'tapa_maletaro', title: 'Tapa del maletero', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'herramientas', title: 'Herramientas', obs_text: undefined, obs_imgs: undefined },
        { value: undefined, prop: 'llanta_auxilio', title: 'Llanta de auxilio', obs_text: undefined, obs_imgs: undefined }
      ]},
      { step: 8, finished: false, title: '¿Nro de llaves?', component: 'escaneo_09', props: [
        {value: undefined, prop: 'numero_llaves', obs_text: undefined, obs_imgs: undefined }
      ]},
    ]
  },
  {
    checkId: 2,
    currentStep: 0,
    steps: [
      { step: 0, finished: false, title: 'Precio Propietario', component: 'avaluo_01' },
    ]
  },
  {
    checkId: 3,
    currentStep: 0,
    steps: [
      { step: 0, finished: false, title: 'Fotos del exterior del vehiculo', component: 'fotos_ext_01',
        props: [
          { value: false, prop: 'foto_frontal', title: 'Cargar foto frontal del vehículo', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_diagonal_frontal', title: 'Cargar foto diagonal frontal del vehículo', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_diagonal_trasera', title: 'Cargar foto diagonal trasera del vehículo', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_trasera', title: 'Cargar foto de la parte trasera de su vehículo', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_capo_abierto', title: 'Cargar foto con el capó abierto (motor)', img: undefined, imgUrl: undefined },
        ]
      },
      { step: 1, finished: false, title: 'Fotos del exterior del vehiculo 2', component: 'fotos_ext_02', 
        props: [
          { value: false, prop: 'foto_llanta_delantera_derecha', title: 'Cargar foto de la llanta delantera derecha', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_llanta_delantera_izquierda', title: 'Cargar foto de la llanta delantera izquierda', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_llanta_trasera_derecha', title: 'Cargar foto de la llanta trasera derecha', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_llanta_trasera_izquierda', title: 'Cargar foto de la llanta trasera izquierda', img: undefined, imgUrl: undefined },
        ]
      },
      { step: 2, finished: false, title: 'Fotos del exterior del vehiculo 3', component: 'fotos_ext_03',
        props: [
          { value: false, prop: 'foto_llanta_auxilio', title: 'Cargar foto de la llanta de auxilio', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_maletero_abierto', title: 'Cargar foto con el maletero abiero', img: undefined, imgUrl: undefined },
        ]
      },
      { step: 3, finished: false, title: 'Fotos del interior del vehiculo', component: 'fotos_int_01',
        props: [
          { value: false, prop: 'foto_volante', title: 'Cargar foto del volante', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_tablero_01', title: 'Cargar foto del tablero 1', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_tablero_02', title: 'Cargar foto del tablero 2', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_asientos_delanteros', title: 'Cargar fotos de los asientos delanteros', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_asientos_traseros', title: 'Cargar fotos de los asientos traseros', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_caja_cambio', title: 'Cargar fotos de la caja de cambio', img: undefined, imgUrl: undefined },
          { value: false, prop: 'foto_puerta_conductor_adentro', title: 'Cargar fotos de los puerta del conductor desde adentr', img: undefined, imgUrl: undefined }
        ]
      },
    ]
  }
]

const checks: Tcheck[] = [
  { checkId: 0, finished: false, title: 'Datos generales', step: 0 },
  { checkId: 1, finished: false, title: 'Inspeccion', step: 0 },
  { checkId: 2, finished: false, title: 'Avalúo', step: 0 },
  { checkId: 3, finished: false, title: 'Fotos para la publicacion', step: 0 },
]

const initialState: TcheckOnCourse = {
  checks,
  activeCheck: null,
  checkSteps,
}

const enCursoSlice = createSlice({
  name: 'enCurso',
  initialState,
  reducers: {
    setActiveCheck(state, action: PayloadAction<number|null>) {
      state.activeCheck = action.payload
    },
    setFinishedCheck(state, action: PayloadAction<number>) {
      state.checks = state.checks.map(item => {
        if (item.checkId === action.payload) return { ...item, finished: true }
        return item
      })
    },
    setFinishedStep(state) {
      const currentCheckStep = state.checkSteps.find(check => check.checkId === state.activeCheck)
      if (currentCheckStep) {
        const currentStep = currentCheckStep.steps.find(step => step.step === currentCheckStep.currentStep)

        let tempCheckSteps: any = []
        for (let checkSteps of state.checkSteps ) {
          if (checkSteps.checkId !== currentCheckStep.checkId) tempCheckSteps.push(checkSteps)
          else {
            tempCheckSteps.push({
              ...checkSteps,
              steps: checkSteps.steps.map(step => {
                if (step.step !== currentStep?.step) return step
                return {
                  ...step,
                  finished: true,
                }
              })
            })
          }

        }

        state.checkSteps = tempCheckSteps

        // state.checkSteps = state.checkSteps.map(checkSteps => {
          // if (checkSteps.checkId !== currentCheckStep.checkId) return checkSteps

          // return {
          //   ...checkSteps,
          //   steps: checkSteps.steps.map(step => {
          //     if (step.step !== currentStep?.step) return step
          //     return {
          //       ...step,
          //       finished: true,
          //     }
          //   })
          // }
        // })
      }
      
    },
    nextStep(state) {
      if (state.activeCheck !== null) {
        const currentCheckStep = state.checkSteps.find(check => check.checkId === state.activeCheck)
        const currentCheckStepIdx = state.checkSteps.findIndex(check => check.checkId === state.activeCheck)

        if (currentCheckStep) {
          if (currentCheckStep.currentStep !== null &&
            currentCheckStep.currentStep + 1 < currentCheckStep.steps.length ) {
            state.checkSteps[currentCheckStepIdx].currentStep += 1
          }
        }
      }
    },
    backStep(state) {
      if (state.activeCheck !== null) {
        const currentCheckStep = state.checkSteps.find(check => check.checkId === state.activeCheck)
        const currentCheckStepIdx = state.checkSteps.findIndex(check => check.checkId === state.activeCheck)

        if (currentCheckStep) {
          if (currentCheckStep.currentStep !== null && currentCheckStep.currentStep > 0 ) {
            state.checkSteps[currentCheckStepIdx].currentStep -= 1
          }
        }
      }
    },
    updatePropInStepCheck0(state, action: PayloadAction<{
      stepIdx: number, payload: any
    }>) {
        const { stepIdx, payload } = action.payload
        state.checkSteps[0].steps[stepIdx].props = {
          ...state.checkSteps[0].steps[stepIdx].props,
          ...payload
      }
    },
    updatePropInStep(state, action: PayloadAction<{
      stepIdx: number, propIdx: number, payload: any 
    }>) {
        const { stepIdx, propIdx,  payload } = action.payload
        state.checkSteps[1].steps[stepIdx].props[propIdx] = {
          ...state.checkSteps[1].steps[stepIdx].props[propIdx],
          ...payload
      }
    },
    updatePropInStepCheckPhotos(state, action: PayloadAction<{
      stepIdx: number, propIdx: number, payload: any 
    }>) {
        const { stepIdx, propIdx,  payload } = action.payload
        state.checkSteps[3].steps[stepIdx].props[propIdx] = {
          ...state.checkSteps[3].steps[stepIdx].props[propIdx],
          ...payload
      }
    },
    loadInspeccion(state, action: PayloadAction<TcheckOnCourseResponse>) {
      const entries = Object.entries(action.payload);

      Object.entries(state.checkSteps[0].steps).forEach(([stepKey, stepValue], stepIdx ) =>{
        Object.entries(stepValue.props).forEach(([propKey, propValue], propIdx) => {
          for (let propEntryIdx in entries) {
            if (`${propKey}` === entries[propEntryIdx][0]) {
              state.checkSteps[0].steps[stepIdx].props = {
                ...state.checkSteps[0].steps[stepIdx].props,
                [entries[propEntryIdx][0]]: entries[propEntryIdx][1]
              }
            }
          }
        })
      })

      Object.entries(state.checkSteps[1].steps).forEach(([stepKey, stepValue], stepIdx ) =>{
        Object.entries(stepValue.props).forEach(([propKey, propValue], propIdx) => {
          for (let propEntryIdx in entries) {
            if (`${propValue.prop}` === entries[propEntryIdx][0]) {
              state.checkSteps[1].steps[stepIdx].props[propIdx].value = entries[propEntryIdx][1]
            }

            if (`${propValue.prop}_desc` === entries[propEntryIdx][0]) {
              state.checkSteps[1].steps[stepIdx].props[propIdx].obs_text = entries[propEntryIdx][1] as string
            }
          }
        })
      })

      Object.entries(state.checkSteps[3].steps).forEach(([stepKey, stepValue], stepIdx ) =>{
        Object.entries(stepValue.props).forEach(([propKey, propValue], propIdx) => {
          for (let propEntryIdx in entries) {
            if (`${propValue.prop}_img` === entries[propEntryIdx][0]) {
              console.log(true)
              state.checkSteps[3].steps[stepIdx].props[propIdx].value = entries[propEntryIdx][1] as boolean
            }
          }
        })
      })

      // for (let step of state.checkSteps[3].steps) {
      //   for (let propIdx in step.props) {

      //     for (let propEntryIdx in entries) {
      //       if (`${step.props[propIdx].prop}_img` === entries[propEntryIdx][0]) {
      //         console.log(true)
      //         console.log(step.props[propIdx].prop)
      //         state.checkSteps[3].steps[propIdx].
      //       }
      //     }
      //   }
      // }
    }
  }
})

export const {
  setActiveCheck,
  nextStep,
  backStep,
  setFinishedStep,
  setFinishedCheck,
  loadInspeccion,
  updatePropInStepCheck0,
  updatePropInStep,
  updatePropInStepCheckPhotos
} = enCursoSlice.actions

export default enCursoSlice.reducer