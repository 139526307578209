import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { vehicles } from './VehiclesData2'
import { updateInspeccion } from '../../../api/inspeccion';
import { UserContext } from '../../../context/userContext';
import { setFinishedStep, updatePropInStepCheck0 } from '../enCursoSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TrootState } from '../../../app/rootReducer';

const year = [
  2000, 2001, 2002, 2003, 2004,
  2005, 2006, 2007, 2008, 2009,
  2010, 2011, 2012, 2013, 2014,
  2015, 2016, 2017, 2018, 2019,
  2020, 2021, 2022, 2023, 2024
];

type dataForm = {
  marca: string | undefined,
  modelo: string | undefined,
  version: string | undefined,
  year: number | undefined,
  traccion: string | undefined,
}
const initialForm = {
  marca: undefined,
  modelo: undefined,
  version: undefined,
  year: undefined,
  traccion: undefined,
}

export default function DatosGenerales_01() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [ dataForm, setDataForm ] = useState<dataForm>(initialForm)
  const [ readyToUpload, setReadyToUpload  ] = useState<boolean>(false)

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[0].steps[0]
  })

  useEffect(() => {
    setDataForm((state) => {
      return {
        ...state,
        ...currentStep.props
      }
    })

    const therIsUndefined = Object.entries(currentStep.props).some(([prop, valor]) =>  valor === undefined)
    if (!therIsUndefined) dispatch(setFinishedStep())
  }, [])

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    event.preventDefault()

    switch(event.target.name) {
      case 'check_marca':
        setDataForm({ ...dataForm, marca: event.target.value as keyof typeof vehicles})
        dispatch(updatePropInStepCheck0({ stepIdx: 0, payload: { marca: event.target.value } }))
        break;
      case 'check_modelo':
        setDataForm({ ...dataForm, modelo: event.target.value })
        dispatch(updatePropInStepCheck0({ stepIdx: 0, payload: { modelo: event.target.value } }))
        break;
      case 'check_version':
        let traccion = '4x2'
        if (event.target.value.match(/[4]\s{0,}[xX]\s{0,}[4]/)) {
          traccion = '4x4'
        }

        setDataForm({ ...dataForm, version: event.target.value, traccion })
        dispatch(updatePropInStepCheck0({ stepIdx: 0, payload: { version: event.target.value, traccion } }))
        break;
      case 'check_ano':
        setDataForm({ ...dataForm, year: parseInt(event.target.value) })
        dispatch(updatePropInStepCheck0({ stepIdx: 0, payload: { year: event.target.value } }))
        break;
    }    
  }

  useEffect(() => {
    isReadyToUpload()
  }, [dataForm])

  function isReadyToUpload() {
    const therIsUndefined = Object.entries(dataForm).some(([prop, valor]) =>  valor === undefined)
    if (!therIsUndefined) setReadyToUpload(true)
  }

  function uploadData() {
    if (userData.data?.inspeccionEncurso) {
      updateInspeccion({ task_id: userData.data.inspeccionEncurso, ...dataForm })
      .then((resp) => {
        if (resp.result && resp.result === 'success') {
          dispatch(setFinishedStep())
        }
      })
    }
  }


  return(
    <Formulario>

      { currentStep?.finished && <Finalizado> Finalizado </Finalizado> }

      <Input>
        <Label htmlFor='check_marca'> Marca: </Label>
        <Select
          id="check_marca"
          name="check_marca"
          value={dataForm.marca ? dataForm.marca : 'DEFAULT'}
          onChange={inputChange}
        >
          <option value="DEFAULT" disabled>Seleccione una Marca</option>
          {
            Object.keys(vehicles).map((marca, index) => (
              <option value={marca} key={`marca${index}`}>
                { marca }
              </option>
            ))
          }
        </Select>
      </Input>

      <Input>
        <Label htmlFor='check_modelo'> Modelo: </Label>
        <Select
          id="check_modelo"
          name='check_modelo'
          value={dataForm.modelo ? dataForm.modelo : 'DEFAULT'}
          onChange={inputChange}
        >
          <option value="DEFAULT" disabled>Seleccione primero una Marca</option>
          {
            dataForm.marca ? vehicles[dataForm.marca as keyof typeof vehicles].map((vehicle, index) => (
              <option value={vehicle} key={`vehicle${vehicle}`}>
                { vehicle }
              </option>
            ))
            :
            null
          }
        </Select>
      </Input>

      <Input>
        <Label htmlFor='check_version'> Version: </Label>
        <Field
          type='text'
          id='check_version'
          name='check_version'
          value={dataForm.version}
          onChange={inputChange}
        />
      </Input>

      <Input>
        <Label htmlFor='check_ano'> Año: </Label>
        <Select
          id="check_ano"
          name='check_ano'
          value={dataForm.year ? dataForm.year : 'DEFAULT'}
          onChange={inputChange}
        >
          <option value="DEFAULT" disabled>Año</option>
          {
            year.map((year, index) => (
              <option value={year} key={`year${index}`}>
                { year }
              </option>
            ))
          }
        </Select>
      </Input>

      <SaveInfo
        onClick={() => uploadData()}
        disabled={!readyToUpload}
      >
        Guardar Info
      </SaveInfo>

    </Formulario>
  )
}

const Formulario = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 300px;
  margin: 5px auto auto auto;
`
const Input = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`
const Label = styled.label`
  display: flex;
  font-family: Barlow;
`
const Field = styled.input`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin-top: 15px;
  padding: 5px 5px 5px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
`
const Select = styled.select`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin-top: 5px;
  padding: 5px 5px 5px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
`
const SaveInfo = styled.button`
  margin-top: 30px;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 8px 8px -4px gray;
`

const Finalizado = styled.p`
  margin: 0px;
  color: #53DD6C;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 5px;
`