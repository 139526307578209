import React, { createContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import ls, {get,set} from "local-storage";
import useConfig from "../hooks/useConfig";

const cookies = new Cookies();

type userData = {
    status: string,
    data: {
        username: string,
        email: string,
        imgProfile: string|null,
        uuid: string | null,
        inspeccionEncurso: string | null,
    } | undefined
}
const initialUserData = {
    status: 'initial',
    data: undefined,
}

type ContextType = {
    userData: userData,
    setUserData: (user: userData) => void;
    refreshUserInfo: number,
    setRefreshUserInfo: (timestamp: number) => void;
    closeCurrentSession: number,
    dispatchCloseCurrentSession: (timestamp: number) => void,
};

export const UserContext = createContext<ContextType>({
    userData: initialUserData,
    setUserData: (userData: userData) => {},
    refreshUserInfo: Date.now(),
    setRefreshUserInfo: (timestamp: number) => {},
    closeCurrentSession: 0,
    dispatchCloseCurrentSession: (timestamp: number) => {},
});

type providerProps = {
    children: React.ReactNode;
};

const UserProvider: React.FC<providerProps> = ({ children }) => {
    const config = useConfig();
    const [userData, setUserData] = useState<userData>(initialUserData);
    const [ refreshUserInfo, setRefreshUserInfo ] = useState(Date.now());
    const [ closeCurrentSession, dispatchCloseCurrentSession ] = useState(0);

    function closeSession() {
        console.log('closing session');
        setUserData({ status: 'loading', data: undefined });
        cookies.remove(config.app.COOKIE_USER);
        window.location.replace(config.app.ROOT_URL);
    };

    function updaterUserData() {
        const payload = {
            type: "getInspector",
            uuid: get<string>('uuid_inspector'),
        };

        fetch(`${config.app.BACKEND_URL}/inspector`, {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            console.log('useContext resp:', data);

            if (data.error) { throw new Error(data.error) }      
            const { username, profile } = data;

            setUserData({
                status: 'logged',
                data: {
                    ...data,
                    // uuid: uuid_inspector !== null ? uuid_inspector : null,
                    uuid: get<string>('uuid_inspector'),
                    email: data.email,
                    inspeccionEncurso: data.inspeccion_encurso ? data.inspeccion_encurso : null,
                }
            });
        })
        .catch((error) => {
            console.log('useContext Catch:', error);        
        });
    }

    useEffect(() => {

        // if (cookies.get(config.app.COOKIE_USER)) {
        const uuid_inspector = ls('uuid_inspector');
        if (uuid_inspector !== null) {
            setUserData({
                status: 'loading',
                data: undefined,
            });

            updaterUserData()
        }

    }, [refreshUserInfo])

    useEffect(() => {

        if (closeCurrentSession > 0) {
            //closeSession()
        }

    }, [closeCurrentSession])

    return (
        <UserContext.Provider value={{ userData, setUserData, refreshUserInfo, setRefreshUserInfo, closeCurrentSession, dispatchCloseCurrentSession }}>
            { children }
        </UserContext.Provider>
    );
};

export default UserProvider;