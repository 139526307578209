import React from 'react'
import styled from 'styled-components'

import closeIcon from '../../assets/icons/ui/closeGrey.png'

interface thisProps { children: JSX.Element, closeModal: () => void }
export default function Modal(props: thisProps) {

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      id: string
    }

    if(target.id.match(/modalBackground|closeModalIcon/)) {
      props.closeModal()
    }
  }
  
  return(
    <ModalBackground
      id='modalBackground'
      style={{zIndex: 998 }}
      onClick={hiddeModalFn}
    >
      <ModalContainer style={{zIndex: 999 }}>
        <CloseIcon
          id='closeModalIcon'
          src={closeIcon}
        />
        { props.children }
      </ModalContainer>
    </ModalBackground>
  )
}

const ModalBackground = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  margin: auto;
  border-radius: 10px;
`
const CloseIcon = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 18px;
`