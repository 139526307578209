import React, { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { TrootState } from '../../../app/rootReducer'
import { setFinishedStep } from '../enCursoSlice'
import { updateInspeccion } from '../../../api/inspeccion'
import { UserContext } from '../../../context/userContext'

export default function Escaneo_10() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [ N_llaves, setN_llaves ] = useState<number | undefined>(undefined)

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[1].steps[2]
  })

  useEffect(() => {
    if (currentStep.props[0].value) {
      setN_llaves(Number(currentStep.props[0].value))
    }
  }, [])

  useEffect(() => {
    if (N_llaves) {
      dispatch(setFinishedStep())
    }
  }, [N_llaves])

  function setValue(valor: number) {
    setN_llaves(valor)
    if (userData.data?.inspeccionEncurso) {
      updateInspeccion({ 
        task_id: userData.data.inspeccionEncurso,
        numero_llaves: valor
      })
    }
  }

  return(
    <Formulario finished={currentStep?.finished ? true : false}>

      <Button
        onClick={() => { setValue(1) }}
        selected={ N_llaves === 1 }
      >
        1
      </Button>

      <Button
        onClick={() => { setValue(2) }}
        selected={ N_llaves === 2 }
      >
        2
      </Button>

    </Formulario>
  )
}

interface Tformulario { finished: boolean }
const Formulario = styled.div<Tformulario>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 20px;
  ${(args) => args.finished && `
    opacity: 0.5;
  `}
`
interface Tbutton { selected: boolean }
const Button = styled.button<Tbutton>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 200px;
  margin: 40px auto 10px auto;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
  ${(args) => args.selected && `
    background-color: #ACF39D;
  `}
`
const Label = styled.label`
  display: flex;
`
