import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux';
import { Ttask } from '../../app/tasksTypes';
import { meses, dias } from '../../app/consts'

import Modal from '../../components/modal/modal'
import ModalInspeccionToDo from './modalInspeccionToDo';

interface thisProps { task: Ttask }
export default function TaskInspeccion(props: thisProps) {
  const { task } = props;
  const[ showModal, setShowModal ] = useState(false)
  const date = new Date(task.fecha_inspeccion)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  return(
    <>
      <TaskContainer onClick={() => displayModal()}>
        <FechaInspeccion>
          <Dia> { dias[date.getDay()] } </Dia>
          <Fecha> { date.getDate() }, {meses[date.getMonth()]}: </Fecha>
          <Hora> { task.hora_inspeccion } </Hora>
        </FechaInspeccion>

        <TaskSummary>
          <Text> { task.address } </Text>
          <Text> Vehiculo: { task.marca } </Text>
          <Text> Modelo: { task.modelo } </Text>
        </TaskSummary>
      </TaskContainer>

      { showModal && 
        <Modal closeModal={hiddeModal}>
          <ModalInspeccionToDo task={task} />
        </Modal>
      }

    </>
  )
}

const TaskContainer = styled.div`
  margin-top: 20px;
`
const FechaInspeccion = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-family: Montserrat;
  align-items: baseline;
`
  const Dia = styled.div`
  `
  const Fecha = styled.div`
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
  `
  const Hora = styled.div`
    margin-left: 10px;
  `

const TaskSummary = styled.div`
  margin-top: 10px;
  border: solid 1px #000000;
  background-color: #F9F6EE;
  justify-content: center;
  padding: 10px;
`
const Text = styled.div`
  text-align: left;
`