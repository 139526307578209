import React, { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { TrootState } from '../../../app/rootReducer'
import { setFinishedStep } from '../enCursoSlice'
import { updateInspeccion } from '../../../api/inspeccion'
import { UserContext } from '../../../context/userContext'
import { updatePropInStep } from '../enCursoSlice'

export default function Escaneo_03() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [ caja, setCaja ] = useState<undefined | string>(undefined)

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[1].steps[2]
  })

  useEffect(() => {
    console.log(currentStep)
    if (currentStep.props[0].value) {
      setCaja(currentStep.props[0].value)
    }
  }, [])

  useEffect(() => {
    if (caja) {
      dispatch(setFinishedStep())
    }
  }, [caja])

  function setValue(valor: string) {
    setCaja(valor)
    if (userData.data?.inspeccionEncurso) {
      updateInspeccion({ 
        task_id: userData.data.inspeccionEncurso,
        transmision: valor
      })

      dispatch(updatePropInStep({ stepIdx: 2, propIdx: 0, payload: { value: valor } }))
    }
  }

  function newCheck(step: number, idx: number, prop: string, value: boolean | null | undefined, source: string) {
    if (userData.data?.inspeccionEncurso) {
      if (value !== undefined) {
        updateInspeccion({ 
          task_id: userData.data.inspeccionEncurso,
          [prop]: value,
        })
        .then((resp) => {
          
        })
      }

      dispatch(updatePropInStep({ stepIdx: step, propIdx: idx, payload: { value } }))
    }
  }

  return(
    <Formulario finished={currentStep?.finished ? true : false}>

      <Button
        onClick={() => {
          setValue('mecanica')
          newCheck( 3,  3, 'secuencial', null, 'check' )
          newCheck( 3,  4, 'estado_caja_cambio', undefined, 'check' )
          newCheck( 3,  5, 'sistema_embrague', undefined, 'check' )
        }}
        selected={ caja === 'mecanica' }
      >
        Mecánica
      </Button>

      <Button
        onClick={() => {
          setValue('automatica')
          newCheck( 3,  3, 'secuencial', undefined, 'check' )
          newCheck( 3,  4, 'estado_caja_cambio', null, 'check' )
          newCheck( 3,  5, 'sistema_embrague', null, 'check' )
        }}
        selected={ caja === 'automatica' }
      >
        Automática
      </Button>

    </Formulario>
  )
}

interface Tformulario { finished: boolean }
const Formulario = styled.div<Tformulario>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 20px;
  ${(args) => args.finished && `
    opacity: 0.5;
  `}
`
interface Tbutton { selected: boolean }
const Button = styled.button<Tbutton>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 200px;
  margin: 40px auto 10px auto;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
  ${(args) => args.selected && `
    background-color: #ACF39D;
  `}
`
const Label = styled.label`
  display: flex;
`
