import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tfile } from '../modalUploadObservacion'
import { useDispatch, useSelector } from 'react-redux'
import { setFinishedStep, updatePropInStep } from '../enCursoSlice'
import { updateInspeccion } from '../../../api/inspeccion'
import { UserContext } from '../../../context/userContext'
import { TformularioCheckGrid } from '../types'
import { TrootState } from '../../../app/rootReducer'

import FormularioGrid from './formularioGrid'
import Modal from '../../../components/modal/modal'
import ModalUploadObservacion from '../modalUploadObservacion'


interface Tprops { initialFormValues: TformularioCheckGrid[], finished: boolean }
export default function FormularioChecks(props: Tprops) {
  const { initialFormValues, finished } = props

  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [formData, setFormData] = useState<TformularioCheckGrid[]>(initialFormValues)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentProp, setCurrentProp] = useState<TformularioCheckGrid | undefined>(undefined)

  const tipoTransmision = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[1].steps[2].props[0].value
  })

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  function newCheck(idx: number, prop: string, value: boolean | null, source: string) {

    if ((prop === 'estado_caja_cambio' || prop === 'sistema_embrague') && tipoTransmision === 'automatica')
    return null

    if (prop === 'secuencial' && tipoTransmision === 'mecanica')
    return null

    const index = formData.findIndex((item) => item.prop === prop)

    if (userData.data?.inspeccionEncurso) {
      updateInspeccion({ 
        task_id: userData.data.inspeccionEncurso,
        [prop]: value,
      })
      .then((resp) => {
        dispatch(updatePropInStep({ stepIdx: 0, propIdx: idx, payload: { value } }))        
      })
    }

    setFormData(formData.map((item, idx) => {
      if (idx === index) return { ...item, value: value }
      return item
    }))

    setCurrentProp(formData.find((item) => item.prop === prop))

    if (source === 'upload') displayModal()
  }

  function updateObservacionProps(prop: string, observacion: string, img: Tfile ) {
    setFormData(formData.map(item => {
      if (item.prop === prop) return { ...item, obs_text: observacion, obs_imgs: img }
      else return item
    }))

    const index = formData.findIndex((item) => item.prop === prop)
    dispatch(updatePropInStep({
      stepIdx: 0, propIdx: index,
      payload: { 
        obs_text: observacion,
        obs_imgs: img ? true : false
      }
    }))
  }

  useEffect(() => {
    const evalueProps: boolean[] = formData.map(item => {
      if (item.value) return true
      else {
        if (item.value === false) {
          if (item.obs_text && item.obs_imgs) return true
          else return true
        }

        if (item.value === null) return true
        return false
      }
    })

    const thereIsUnfilled = evalueProps.some((item) => item === false)

    if (!thereIsUnfilled) {
      dispatch(setFinishedStep())
    }

  }, [formData])


  return(
    <>
      <FormularioGrid
        newCheck={newCheck}
        formData={formData}
        finished={finished}
      />

      { showModal && 
        <Modal closeModal={hiddeModal}>
          <ModalUploadObservacion
            prop={currentProp}
            updateForm={updateObservacionProps}
            hiddeModal={hiddeModal}
          />
        </Modal>
      }
    </>
  )
}