import React, { useContext } from 'react';
import './loading.css'

import loadingGif from '../../assets/gifs/loading.gif';
import { UIContext } from '../../context/uiContext';

export default function Loading() {
    const { uiState } = useContext(UIContext);

    return (
        <div 
            className={ uiState.loading ? 'loading-background': 'loading-hidden'}
        >
            <div className='loading-container'>

                <div className='loading-icon-container'>

                    <div className='loading-icon-background'/>
                    <p className='loading-icon-title'>
                        CARP 10
                    </p>

                    <div className='loading-icon-flag'/>
                    <div className='loading-icon-flag'/>
                    <div className='loading-icon-flag'/>

                </div>

                <img
                    src={loadingGif}
                    className='loading-img-loading'
                />
            </div>
        </div>
    )
}
