import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setFinishedStep, updatePropInStepCheckPhotos } from '../enCursoSlice'
import { TrootState } from '../../../app/rootReducer'

import Modal from '../../../components/modal/modal'
import ModalUploadFoto from '../modalUploadFoto'

import uploadFoto  from '../../../assets/icons/ui/photo.png'
import uploadedFoto  from '../../../assets/icons/ui/photo_uploaded.png'

export type TpropFotos = {
  value: boolean,
  prop: string,
  title: string,
  img: Blob | undefined,
  imgUrl: string | undefined,
}

const initialForm = [
  { value: false, prop: 'foto_llanta_delantera_derecha', title: 'Cargar foto de la llanta delantera derecha', img: undefined, imgUrl: undefined },
  { value: false, prop: 'foto_llanta_delantera_izquierda', title: 'Cargar foto de la llanta delantera izquierda', img: undefined, imgUrl: undefined },
  { value: false, prop: 'foto_llanta_trasera_derecha', title: 'Cargar foto de la llanta trasera derecha', img: undefined, imgUrl: undefined },
  { value: false, prop: 'foto_llanta_trasera_izquierda', title: 'Cargar foto de la llanta trasera izquierda', img: undefined, imgUrl: undefined },
]

export default function Fotos02_exterior() {
  const dispatch = useDispatch()
  const [ showModal, setShowModal] = useState<boolean>(false);
  const [ uploads, setUploads ] = useState<TpropFotos[]>(initialForm)
  const [ currentUpload, setCurrentUpload ] = useState<TpropFotos | undefined>(undefined)

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[3].steps[1]
  })

  useEffect(() => {
    setUploads((state) => {
      return state.map((prop, idx) => {
        const entry = currentStep.props.find((propEntry) => propEntry.prop === prop.prop)

        if (entry)
        return { ...prop, value: entry.value }
        else
        return { ...prop }
      })
    })
  }, [])

  useEffect(() => {
    const evalueProps: boolean[] = uploads.map(item => {
      return item.value
    })

    const thereIsUnfilled = evalueProps.some((item) => item === false)

    if (!thereIsUnfilled) {
      dispatch(setFinishedStep())
    }

  }, [uploads])

  function setBlob(prop: string, blobImg: Blob, urlImg: string) {
    const index = uploads.findIndex((item) => item.prop === prop)

    setUploads(uploads.map((item, idx) => {
      if (idx === index) return { ...item, value: true, img: blobImg, imgUrl: urlImg }
      return item
    }))

    dispatch(updatePropInStepCheckPhotos({ stepIdx: 1, propIdx: index, payload: { value: true } }))

    hiddeModal()
  }

  function clickUpload(prop: string) {
    setCurrentUpload(uploads.find((item) => item.prop === prop))
    displayModal()
  }

  return(
    <>
      <FotosContainer finished={currentStep?.finished ? true : false}>
        { uploads.map((upload, idx) => (
          <Upload
            key={`upload${idx}`}
            onClick={() => clickUpload(upload.prop)}
          >
            <UploadIcon src={ upload.imgUrl ? upload.imgUrl : upload.value ? uploadedFoto : uploadFoto } />
            { upload.value && 
              <SubidoText> Guardado </SubidoText>
            }
            <Text> { upload.title } </Text>
          </Upload>
        ))}

      </FotosContainer>

      { showModal && 
        <Modal
          closeModal={hiddeModal}          
        >
          <ModalUploadFoto
            prop={currentUpload}
            setFoto={setBlob}
            hiddeModal={hiddeModal}
          />
        </Modal>
      }
    </>
  )
}

interface TfotosContainer { finished: boolean }
const FotosContainer = styled.div<TfotosContainer>`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  ${(args) => args.finished && `
    opacity: 0.5;
  `}
`
const Upload = styled.div`
  position: relative;
  margin-top: 10px;
  width: 40%;
`
const UploadIcon = styled.img`
  width: 150px;
`
const Text = styled.div`
  font-family: Barlow;
  font-size: 12px;
`
const SubidoText = styled.div`
  font-family: Barlow;
  font-size: 20px;
  font-weight: 800;
  position: absolute;
  top: 10px;
  left: 50px;
  color: #9b4573;
`