import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { showNotification } from '../../app/uiSlice';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context/userContext'
import { loadMyTasks } from '../../api/inspector'
import { Ttask } from '../../app/tasksTypes';
import { useDispatch } from 'react-redux';

import TaskInspeccion from './inspeccionToDo';



export default function InspeccionesToDo() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { userData } = useContext(UserContext);
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ tasks, setTasks ] = useState<Ttask[]>([])

  useEffect(() => {
    console.log(pathname);
    if (userData.data?.uuid && pathname === '/misinspecciones') {
      console.log('fetching');
      loadMyTasks()
      .then((data) => {
        setLoading(false)
        setTasks(data.items)
      })
      .catch((err: string) => {
        setLoading(false)
        dispatch(showNotification({
          type: 'error', message: err, toogle: Date.now()
        }))
      })
    }
  }, [userData, pathname])

  return(
    <MisTareasContainer className='MisTareas view'>
      <Title> Mis Inspecciones Asignadas </Title>

      { loading && 
        <>
          <LoadingTask/>
          <LoadingTask/>
          <LoadingTask/>
          <LoadingTask/>
        </>
      }

      {!loading && 
        <ListTasks>
          {tasks.length > 0 ?
            tasks.map((task, idx) => 
            <TaskInspeccion key={`idx${idx}`} task={task}/>)
              :
            <NoTasks> No tienes tareas asignadas </NoTasks>
          }
        </ListTasks>
      }

    </MisTareasContainer>
  )
}

const MisTareasContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow: auto;
`
const Title = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
`
const ListTasks = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 13;
  justify-content: flex-start;
  width: 90%;
  margin-bottom: 20px;
`
const NoTasks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 13;
  font-family: Barlow;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  //border: dashed 2px #000000;
  color: gray;
  width: 150px;
  margin: auto;
  margin-bottom: 100px;
`

const LoadingTask = styled.div`
  width: 90%;
  height: 85px;
  border-radius: 5px;
  background-color: red;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #D6D6D6 0%, #F0EFED 50%, #D6D6D6 100%);
  background-size: 800px 50px;
  position: relative;
  margin-top: 10px;
`