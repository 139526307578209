import React from 'react'
import styled from 'styled-components'

export default function Avaluo02PrecioEvaluador() {

  return(
    <Formulario>

      <Input>
        <Label> Precio Minimo </Label>
        <Field type='number'/>
      </Input>

      <Input>
        <Label> Precio Maximo </Label>
        <Field type='number'/>
      </Input>

    </Formulario>
  )
}

const Formulario = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 20px;
`

const Input = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 270px;
  margin: 20px auto 10px auto;
`
const Label = styled.label`
  display: flex;
  font-family: Barlow;
`
const Field = styled.input`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin-top: 15px;
  padding: 5px 5px 5px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
`