import { checkPrimeSync } from 'crypto';
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { getPresignedUrl, uploadImage, updateInspeccion } from '../../api/inspeccion';
import { UserContext } from '../../context/userContext';
import { TpropFotos } from './formularios/fotos01_exterior'

import loadingGif from '../../assets/gifs/loading.gif'

interface Tfile { blob: Blob, dataUrl: string, file: File }
interface thisProps { 
  prop: TpropFotos | undefined,
  setFoto: (prop: string, blobImg: Blob, urlImg: string) => void,
  hiddeModal: () => void,
}

export default function ModalUploadFoto(props: thisProps) {
  const { userData } = useContext(UserContext)
  const { prop, setFoto, hiddeModal } = props
  const [ files, setFiles] = useState<Tfile[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)

  async function onFileChange(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      files: FileList;
    };

    const filesRaw: File[] = []

    for (let file in target.files ) {
      if (typeof target.files[file] === 'object') {
        filesRaw.push(target.files[file])
      }
    }

    const files = filesRaw.map(file => {
      const reader = new FileReader()

      return new Promise<Tfile>(resolve => {
        reader.onload = (e) => {
          if (e.target?.result) {
            let binary = window.atob(e.target.result.toString().split(',')[1])
            let array = []
            for (var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i))
            }

            let blobData = new Blob([new Uint8Array(array)], {type: '.jpeg'})
            resolve({ blob: blobData, dataUrl: e.target.result.toString(), file })
          }
        }

        reader.readAsDataURL(file)
      })
    })

    const resultFiles = await Promise.all(files)
    setFiles([...resultFiles])
  }

  function uploadFoto() {
    const task_id = userData.data?.inspeccionEncurso
    setLoading(true)

    if (task_id && prop) {
      getPresignedUrl({ task_id, prop: prop.prop, imageName: `fotos/${prop.prop}.jpeg` })
      .then(resp => {
        console.log(resp)
        uploadImage( files[0].file, resp.urlS3Image, files[0].file.type)
        .then(() => {
          if (userData.data?.inspeccionEncurso) {
            updateInspeccion({
              task_id: userData.data.inspeccionEncurso,
              [`${prop.prop}_img`]: true,
            })
            .then((resp) => {
              setLoading(false)
              if (resp.result && resp.result === 'success') {
                setFoto(prop.prop, files[0].blob, files[0].dataUrl)
                hiddeModal()
              }
            })
          }
        })

      })
    }
  }

  return(
    <ModalUploadObservacionContainer>
      <Title> { prop?.title.toUpperCase() } </Title>

      <Input>

      <UploadContainer>
        
        <ThumbsContainer>
          { files.map((img, idx) => (
            <Thumb src={img.dataUrl} key={`file${idx}`}/>
          ))}
        </ThumbsContainer>

        <FileUpload htmlFor="file-upload">
          SUBIR FOTO
        </FileUpload>

        <HiddenInput 
          id='file-upload'
          type="file"
          multiple={false}
          onChange={onFileChange}
          accept="image/jpeg"
        />

        <SaveFoto 
          disabled={files.length === 0 || loading ? true : false}
          ready={files.length !== 0}
          onClick={() => {uploadFoto()}}
        >
          
          { loading ? '' : 'GUARDAR' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </SaveFoto>
      </UploadContainer>
          
      </Input>
    </ModalUploadObservacionContainer>
  )
}

const ModalUploadObservacionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 15px 15px 15px 15px;
  min-width: 290px;
  max-width: 290px;
  min-height: 290px;
  max-height: max(70vh, 290px);
  overflow-y: auto;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  font-family: Barlow;
  font-weight: 600;
  font-size: 15px;
  max-width: 250px;
  margin: auto;
`
const Input = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`

const UploadContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 0px;
`
const FileUpload = styled.label`
  font-family: Barlow;
  font-weight: 600;
  background: #61dafb;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
  width: 240px;
  margin-top: 15px;
`

const HiddenInput = styled.input`
  display: none;
`
const ThumbsContainer = styled.div`
  background-color: rgba(200, 200, 200, 0.4);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: auto;
  border: dashed 1px gray;
  border-radius: 5px;
  padding: 5px;
  width: 260px;
  max-height: 300px;
  min-height: 200px;
  overflow: auto;
`
  const Thumb = styled.img`
    max-height: 300px;
    object-fit: 'scale-down';
    border-radius: 5px;
    margin: 5px;
  `
interface TsaveFoto { ready: boolean }
const SaveFoto = styled.button<TsaveFoto>`
  font-family: Barlow;
  font-weight: 600;
  width: 270px;
  height: 35px;
  background-color: gray;
  border-radius: 5px;
  padding: 5px 15px;
  color: white;
  border: none;
  margin-top: 20px;
  ${(args) => args.ready && `
    background-color: #2C8C99;
  `}
`
interface TloadingGif { isLoading: boolean }
const LoadingGif = styled.img<TloadingGif>`
  width: 23px;
  display: none;
  ${(args) => args.isLoading && `
    display: initial;
  `}
`