import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'
import { TrootState } from '../../../app/rootReducer'
import { setFinishedStep } from '../enCursoSlice'
import { updateInspeccion } from '../../../api/inspeccion'
import { UserContext } from '../../../context/userContext'

export default function Avaluo01_precioCliente() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [minPrice, setMinPrice] = useState<number|undefined>(0)
  const [maxPrice, setMaxPrice] = useState<number|undefined>(0)
  const [readyToUpload, setReady] = useState<boolean>(false)

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[2].steps[0]
  })

  useEffect(() => {
    console.log(currentStep)
  }, [])
  
  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {
    switch (event.target.name) {
      case 'owner_max_price':
          setMaxPrice(Number(event.target.value));
        break;
      case 'owner_min_price':
          setMinPrice(Number(event.target.value));
        break;
    }
  }

  function uploadData() {
    const task_id = userData.data?.inspeccionEncurso
    if (task_id) {
      updateInspeccion({
        task_id,
        ownerMinPrice: minPrice,
        ownerMaxPrice: maxPrice
      })
      .then(resp => {
        if (resp.result && resp.result === 'success') {
          dispatch(setFinishedStep())
        }
      })
    }
  }

  useEffect(() => {
    if (minPrice && maxPrice) setReady(true)
    else setReady(false)
  }, [minPrice, maxPrice])

  return(
    <Formulario finished={currentStep?.finished ? true : false}>

      <Input>
        <Label> Precio Minimo (Propietario) </Label>
        <Field
          type='number'
          name='owner_min_price'
          onChange={inputChange}
        />
      </Input>

      <Input>
        <Label> Precio Maximo (Propietario) </Label>
        <Field
          type='number'
          name='owner_max_price'
          onChange={inputChange}
        />
      </Input>

      <SaveInfo
        onClick={() => uploadData()}
        disabled={!readyToUpload}
      >
        Guardar Info
      </SaveInfo>

    </Formulario>
  )
}

interface Tformulario { finished: boolean }
const Formulario = styled.div<Tformulario>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 20px;
  ${(args) => args.finished && `
    opacity: 0.5;
  `}
`

const Input = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 270px;
  margin: 20px auto 10px auto;
`
const Label = styled.label`
  display: flex;
  font-family: Barlow;
`
const Field = styled.input`
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin-top: 15px;
  padding: 5px 5px 5px 15px;
  box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
`

const SaveInfo = styled.button`
  width: 270px;
  margin: 30px auto 10px auto;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 6px 6px -4px gray;
`