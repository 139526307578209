import React from 'react'
import styled from 'styled-components'

export default function NoFormulario() {

  return(
    <Formulario>
      <p>No Formulario</p>
    </Formulario>
  )
}

const Formulario = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`