import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tview } from '../../context/uiContext';
import { NavLink } from 'react-router-dom';
import { UIContext } from '../../context/uiContext';

interface TviewItem extends Tview {
  active: boolean,
  inCourse: boolean
}

export default function ViewItem(props: TviewItem) {
  const { uiState, setUIState } = useContext(UIContext);
  const [ taskInCourse, setTaskInCourse ] = useState<boolean>(false)

  useEffect(() => {
    if (props.path === '/encurso' && props.inCourse) setTaskInCourse(true)
    else setTaskInCourse(false)

  }, [props.inCourse])

  function HiddeSidebar() {
    setUIState({ ...uiState, showSideBar: false });
  };

  return(
    <ViewNavItem active={props.active}>
      <NavLink 
        to={props.path}
        onClick={() => { HiddeSidebar() }}
      >
        <ButtonLink inCourse={taskInCourse}>
          { props.titleShort }
        </ButtonLink>
      </NavLink>
    </ViewNavItem>
  )
}

interface Tactive { active: boolean }
const ViewNavItem = styled.div<Tactive>`
  font-family: Montserrat;
  font-weight: ${(props) => props.active ? `800` : `500`};
  font-size: 15px;
  & > a {
    //text-decoration: ${(props) => props.active ? `underline` : `none`};
    text-decoration: none;
    color: black;
  }
`
interface Tbutton { inCourse: boolean }
const ButtonLink = styled.div<Tbutton>`
  background-color: rgba(200, 200, 200, 0.2);
  width: 150px;
  margin: 10px auto 10px auto;
  padding: 2px 0px 2px 0px;
  ${ args => args.inCourse && `
      background-color: #61dafb;
      color: white;
      animation-duration: 8s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #f6f7f8;
      background: linear-gradient(135deg, #059DC7 0%, #61dafb 50%, #059DC7 100%);
  `};
`