import React from  'react'
import styled from 'styled-components'
import { TformularioCheckGrid } from '../types'

import checkIcon  from '../../../assets/icons/ui/check-check.png'
import wrongIcon  from '../../../assets/icons/ui/wrong.png'
import uploadIconBad  from '../../../assets/icons/ui/upload-error.png'
import uploadIcon  from '../../../assets/icons/ui/upload.png'
import uploadedIcon  from '../../../assets/icons/ui/uploaded.png'
import noAplica from '../../../assets/icons/ui/no_aplica.png'
import { Boolean } from 'aws-sdk/clients/codepipeline'

interface Tprops {
  newCheck: (idx: number, prop: string, value: boolean | null, source: string) => void,
  formData: TformularioCheckGrid[],
  finished: Boolean
}

export default function FormularioGrid(props: Tprops) {
  const { newCheck, formData, finished } = props

  return(
    <Formulario finished={finished}>
      <Table>
        <tbody>
          <tr>
            <th> </th>
            <th> <Check src={checkIcon}/> </th>
            <th> <Check src={wrongIcon} /> </th>
            <ThNA> { 'No\nAplica' } </ThNA>
            <ThText> Obs </ThText>
          </tr>

          { formData.map((row, idx) => (
            <TR error={row.value === false} key={`index${idx}`}>
              <TD> { row.title } </TD>

              <TDcheck onClick={()=> newCheck(idx, row.prop, true, 'check')}>
                { row.value && <Check src={checkIcon}/>}
              </TDcheck>
              <TDcheck onClick={()=> newCheck(idx, row.prop, false, 'check')}>
                { row.value === false && <Check src={wrongIcon} />}
              </TDcheck>

              <TDcheck
                onClick={() => {
                  newCheck(idx, row.prop, null, 'check')
                }}
              >
                { row.value === null && <UploadIcon valor={false} src={noAplica} /> }
              </TDcheck>

              <TDcheck onClick={()=> newCheck(idx, row.prop, row.value === true, 'upload')}>
                { row.value !== undefined && (row.obs_text || row.obs_imgs) &&
                  <UploadIcon
                    valor={ row.obs_text || row.obs_imgs || !row.value ? false : true }
                    src={ row.obs_text || row.obs_imgs ? uploadedIcon : row.value ? uploadIcon : uploadIconBad }
                  />
                }
              </TDcheck>
            </TR>
          ))}
        </tbody>
      </Table>
    </Formulario>
  )
}

interface Tformulario { finished: boolean }
const Formulario = styled.div<Tformulario>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  max-width: 360px;
  min-width: 360px;
  ${(args) => args.finished && `
    opacity: 0.5;
  `}
`

const Table = styled.table`
  border-collapse: collapse;
`

const TD = styled.td`
  border: 1px solid;
  border-collapse: collapse;
  min-width: 20px;
  max-width: 100px;
  min-height: 20px;
  font-family: Barlow;
  text-align: left;
  padding: 5px 0px 5px 10px;
`

const TDcheck = styled(TD)`
  text-align: center;
  padding: 0px;
`

interface Ttr { error: boolean }
const TR = styled.tr<Ttr>`
  background-color: ${(args) => args.error ? '#e9afaf' : '' };
`

const ThText = styled.th`
  font-size: 12px;
`

const ThNA = styled.th`
  white-space: pre;
  font-size: 11px;
  width: 5px;
`

const Check = styled.img`
  width: 20px;
`
interface Tupload { valor: boolean }
const UploadIcon = styled.img<Tupload>`
  width: 20px;
  ${(args) => args.valor && `
    opacity: 0.2;
  `}
`
