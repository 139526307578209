import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from '../../../app/uiSlice'
import styled from 'styled-components'
import { useReactMediaRecorder  } from "react-media-recorder";
import { ReactMediaRecorder } from "react-media-recorder";

import { getPresignedUrl, uploadImage, updateInspeccion } from '../../../api/inspeccion';
import { TrootState } from '../../../app/rootReducer'
import { setFinishedStep } from '../enCursoSlice'
import { UserContext } from '../../../context/userContext'


type device = { label: string, deviceId: string }
export default function Escaneo_03() {
  const dispatch = useDispatch()
  const { userData } = useContext(UserContext)
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false)
  const [recording, setRecording] = useState(false)
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });

  const currentStep = useSelector((state: TrootState) => {
    return state.enCurso.checkSteps[1].steps[4]
  })

  useEffect(() => { 
    if (currentStep.props[0].value) {
      dispatch(setFinishedStep())
    }
  }, [])

  function uploadData(audio: string) {
    const task_id = userData.data?.inspeccionEncurso

    var request = new XMLHttpRequest();
    request.open('GET', audio, true);
    request.responseType = 'blob';
    request.onload = function() {
      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload =  function(e){
        if (e.target?.result) {
          let binary = window.atob(e.target.result.toString().split(',')[1])
          let array = []

          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }

          let blobData = new Blob([new Uint8Array(array)], {type: '.wav'})
          console.log(blobData)

          if (task_id) {
            getPresignedUrl({ task_id, prop: 'audio_motor', imageName: `audio/audio_motor.wav` })
            .then(resp => {
              console.log(resp)

              uploadImage( blobData, resp.urlS3Image, 'audio/wav')
              .then(() => {
                if (userData.data?.inspeccionEncurso) {
                  updateInspeccion({
                    task_id: userData.data.inspeccionEncurso,
                    sonido_motor: true
                  })
                  .then((resp) => {
                    if (resp.result && resp.result === 'success') {
                      dispatch(setFinishedStep())
                    }
                  })
                }
              })

            })
          }

        }
      };
    };

    request.send();
  }

  useEffect(() => {
    let interval : ReturnType<typeof setInterval> | undefined = undefined

    if (recording) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [running, status, recording]);

  return(
    <Formulario finished={currentStep?.finished ? true : false}>

      <Text> {"(colocar el teléfono cerca del motor y grabar audio de 30 segundos)"} </Text>

      <div>

        <ReactMediaRecorder
          audio
          render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
            <RecorderContainer>
              <p>{status}</p>

              <Chrono isRunning={status === 'recording'} >
                <Status> {status} { ' : ' } </Status>
                <Number>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</Number>
                <Number>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</Number>
                <Number>{("0" + ((time / 10) % 100)).slice(-2)}</Number>
              </Chrono>

              { !recording && 
                <Comenzar
                  onClick={() => { setRecording(true); startRecording(); setTime(0) }}
                >
                  Comenzar a grabar
                </Comenzar> 
              }

              { recording &&
                <Detener
                  onClick={() => { setRecording(false); stopRecording();  }}
                >
                  Detener grabacion
                </Detener>
              }

              <audio src={mediaBlobUrl} controls />

              <SaveInfo
                onClick={() => {
                  if (mediaBlobUrl) uploadData(mediaBlobUrl)
                }}
                disabled={typeof mediaBlobUrl !== 'string'}
              >
                Guardar Info
              </SaveInfo>

            </RecorderContainer>
          )}
        />

      </div>

    </Formulario>
  )
}

interface Tformulario { finished: boolean }
const Formulario = styled.div<Tformulario>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 20px;
  ${(args) => args.finished && `
    opacity: 0.5;
  `}
`

const Text = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 300px;
  margin: auto;
`

const RecorderContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

interface Tchrono { isRunning: boolean }
const Chrono = styled.div<Tchrono>`
  margin: 50px auto 10px auto;
  text-transform: uppercase;
  ${(args) => args.isRunning && `
    color: #c83737
  `}
`
const Status = styled.span`
  font-family: Montserrat;
  font-weight: 600;
`
const Number = styled.span`
  font-family: Barlow;
`
const Comenzar = styled.button`
  margin-top: 20px;
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  width: 270px;
`
const Detener = styled.button`
  margin-top: 20px;
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
`

const SaveInfo = styled.button`
  width: 270px;
  margin: 30px auto 10px auto;
  font-family: 'Barlow';
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 6px 6px -4px gray;
`