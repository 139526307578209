import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ls from 'local-storage';
import useConfig from '../../hooks/useConfig';

import loadingGif from '../../assets/gifs/loading.gif';
import { UIContext } from '../../context/uiContext';

interface Tinputs {
  nombre: string | undefined,
  apellidos: string | undefined,
  cdi: number | undefined,
  email: string | undefined,
  telefono: number | undefined,
  direccion: string | undefined,
}

const undefinedInputs = {
  nombre: undefined,
  apellidos: undefined,
  cdi: undefined,
  email: undefined,
  telefono: undefined,
  direccion: undefined,
}

export default function Registro() {
  const { app } = useConfig();
  const { uiState, setUIState } = useContext(UIContext);
  const [ inputs, setInputs ] = useState<Tinputs>(undefinedInputs);
  const [ buttonDisabled, setButtonDisabled ] = useState<boolean>(true);
  const [ fetching, setFetching ] = useState<boolean>(false);

  function changeForm(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;

    setInputs(values => ({...values, [name]: value}) );
  }

  useEffect(() => {
    setButtonDisabled(Object.entries(inputs).some(([,valor]) => valor === undefined || valor === ''));
  }, [inputs]);

  function registerInspector(event: React.SyntheticEvent) {
    event.preventDefault();
    setFetching(true);

    const payload = {
        type: "registroInspector",
        ...inputs,
        uuid: ls('uuid_inspector'),
    };

    fetch(`${app.BACKEND_URL}/inspector`, {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setFetching(false);
    })
    .catch((error) => {
        console.log('catch', error);
        setFetching(false);
    });
  }

  return(
    <RegistroContainer className='Registro view'>

      <Title>
        Formulario Registro
      </Title>

      <FormRegistro onSubmit={registerInspector}>
        <TextInput
          onChange={changeForm}
          type='text'
          name='nombre'
          placeholder="Nombre"
        />

        <TextInput
          onChange={changeForm}
          type='text'
          name='apellidos'
          placeholder="Apellidos"
        />

        <TextInput
          onChange={changeForm}
          type='number'
          name='cdi'
          placeholder="C.I"
        />

        <TextInput
          onChange={changeForm}
          type='email'
          name='email'
          placeholder="Email"
        />

        <TextInput
          onChange={changeForm}
          type='tel'
          name='telefono'
          placeholder="Telefono"
        />

        <TextInput
          onChange={changeForm}
          type='text'
          name='direccion'
          placeholder="Direccion"
        />

        <ButtonRegistrar
          type='submit'
          disabled={buttonDisabled}
          buttonActive={!buttonDisabled}
        >
          {
            fetching ?
              <> <img src={loadingGif} width={18} height={18} />
                Registrando...
              </>
              :
              <> Registrar </>
          }

        </ButtonRegistrar>

      </FormRegistro>

    </RegistroContainer>
  )
}

const RegistroContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
`

const Title = styled.div`
    font-family: Montserrat;
    font-weight: 600;
    margin: 30px auto 20px auto;
`

const FormRegistro = styled.form`
    display: flex;
    flex-flow: column nowrap;
`
  const TextInput = styled.input`
    margin: 10px auto 10px auto;
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 48%;
    min-width: 250px;
    max-width: 300px;
    background-color: rgb(238, 238, 238);
    font-size: 15px;
  `
  interface TButtonRegistrar { buttonActive: boolean }
  const ButtonRegistrar = styled.button<TButtonRegistrar>`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: ${ (props) => props.buttonActive ? 'blue': 'red' };
    font-family: Montserrat;
    font-weight: 700;
    font-size: 16px;
    border: none;
    color: white;
    width: 50%;
    min-width: 250px;
    max-width: 300px;
    border-radius: 5px;
    padding: 10px;
    margin: 50px auto 10px auto;
    & > img {
      margin-right: 10px
    }
  `